import { IconButton, Paper, Tooltip } from "@mui/material";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from "@mui/material/styles";
import { useThemeContext } from '../themes/ThemeContext';

const ThemeToggle = () => {
    const theme = useTheme();
    const { themeMode, updateThemeMode } = useThemeContext();
    const lightMode = theme.palette.mode === "light";

    const handleThemeModeToggle = () => {
        const newMode = lightMode ? 'dark' : 'light';
        updateThemeMode(newMode);
    };

    return (
        <Paper
            elevation={0}
            sx={{
                position: 'relative',
                width: '64px',
                height: '32px',
                borderRadius: '16px',
                bgcolor: lightMode ? 'grey.200' : '#3c3e40',
                display: 'flex',
                alignItems: 'center',
                padding: '4px',
                transition: 'all 0.3s ease-in-out',
                ml: 2,
                boxShadow: lightMode
                    ? 'inset 0 2px 4px rgba(0,0,0,0.06)'
                    : 'inset 0 2px 4px rgba(0,0,0,0.15)',
            }}
        >
            <IconButton
                onClick={handleThemeModeToggle}
                sx={{
                    padding: 0,
                    width: '24px',
                    height: '24px',
                    position: 'absolute',
                    transform: `translateX(${lightMode ? '0px' : '32px'})`,
                    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                        bgcolor: 'transparent',
                    }
                }}
            >
                <Paper
                    elevation={1}
                    sx={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: lightMode ? 'common.white' : 'grey.600',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                            bgcolor: lightMode ? 'grey.200' : 'grey.500',
                            transform: 'translateY(-1px)',
                        }
                    }}
                >
                    {lightMode ? (
                        <Brightness7Icon
                            sx={{
                                fontSize: 16,
                                color: theme.palette.warning.main,
                            }}
                        />
                    ) : (
                        <Brightness4Icon
                            sx={{
                                fontSize: 16,
                                color: 'grey.100'
                            }}
                        />
                    )}
                </Paper>
            </IconButton>
        </Paper>
    );
};

export default ThemeToggle;