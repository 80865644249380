// material-ui
import logoWhite from 'assets/images/icons/logo.svg';
import logoBlack from 'assets/images/icons/logo_b.svg';
import logoCnBlue from 'assets/images/icons/logo_cn.svg';
import logoCnWhite from 'assets/images/icons/logo_cn_w.png'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
export const Logo100 = () => {
    return <><img src={logoWhite} alt="Logo" width="180" style={{marginTop: 8}} /></>;
};

export const Logo200 = () => {
    return <><img src={logoBlack} alt="Logo" width="180" style={{marginTop: 8}} /></>;
};

export const LogoCnBlue= () => {
    return <><img src={logoCnBlue} alt="Logo" width="180" style={{marginTop: 8}} /></>
}

export const LogoCn50= () => {
    return <><img src={logoCnBlue} alt="Logo" width="50" style={{marginTop: 0}} /></>
}

export const LogoCnWhite= () => {
    return <><img src={logoCnWhite} alt="Logo" width="180" style={{marginTop: 8}} /></>
}
