import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { Box, Typography, TextField, useTheme } from '@mui/material';

const CustomTemplateColorPicker = ({ label, color, onChange, size = 80 }) => {
    const theme = useTheme();

    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>{label}</Typography>
            <Box
                sx={{
                    '.react-colorful': {
                        width: `${size}px !important`,
                        height: `${size}px !important`,
                    },
                    '.react-colorful__saturation': {
                        borderRadius: 1,
                    },

                    '.react-colorful__hue, .react-colorful__alpha': {
                        display: 'none !important',
                    },
                }}
            >
                <HexColorPicker color={color} onChange={onChange} />
            </Box>
            <TextField
                fullWidth
                value={color}
                onChange={(e) => onChange(e.target.value)}
                sx={{
                    mt: 2,
                    width: `90px`,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.dark,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                    },
                }}
            />
        </Box>
    );
};

export default CustomTemplateColorPicker;