import { APP_URL, OFFICE_CLIENT_CODE_IMPORT, OFFICE_PHISH_REPORT_CODE } from "config";
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Grid,
    Snackbar,
    Switch,
    TextField,
    Typography,
    Tabs,
    Tab,
    Alert
} from "@mui/material";
import { useUpdateTenancyMutation} from "../../store/services/tenancy.service";
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from 'react-router-dom';
import { getCardColor } from "../../utils/colorUtils";

PhishReportButton.propTypes = {
    data: PropTypes.object.isRequired
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`reports-tabpanel-${index}`}
            aria-labelledby={`reports-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `reports-tab-${index}`,
        'aria-controls': `reports-tabpanel-${index}`,
    };
}

export default function PhishReportButton(props) {
    const { data } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const [subTabValue, setSubTabValue] = useState(0);

    const [tenancyData, setTenancyData] = useState(data);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [isProcessingCallback, setIsProcessingCallback] = useState(false);

    const [updateTenancy] = useUpdateTenancyMutation();

    const startPhishReportAuth = () => {
        window.open(
            `https://login.microsoftonline.com/common/adminconsent?client_id=${OFFICE_PHISH_REPORT_CODE}&redirect_uri=${APP_URL}/outlookPluginHandler&state=${data.tenancyId}`,
            "_blank"
        );
    };

    const handleSubTabChange = (event, newValue) => {
        setSubTabValue(newValue);
    };

    const handleSave = () => {
        updateTenancy({ data: tenancyData, tenancyId: tenancyData.tenancyId })
            .then(() => {
                setSnackbarMessage("Phish report button settings updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarMessage("Error updating phish report button settings.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                console.error("Error updating phish report button settings:", error);
            });
    };

    const handleForwardPhish = () => {
        setTenancyData(prevData => ({ ...prevData, forwardPhish: !prevData.forwardPhish }));
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={subTabValue} onChange={handleSubTabChange} aria-label="reports tabs">
                        <Tab label="Settings" {...a11yProps(0)} />
                        <Tab label="Install" {...a11yProps(1)} />
                        <Tab label="Update" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={subTabValue} index={0}>
                    <Grid container spacing={2} xs={12}>
                        <Grid item xs={6}>
                            <MainCard content={true} boxShadow={true} border={false} sx={{ bgcolor: getCardColor(lightMode) }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <Typography variant="h4">
                                        Forward Phish Emails
                                    </Typography>

                                    <Switch
                                        color="primary"
                                        checked={tenancyData.forwardPhish}
                                        onChange={handleForwardPhish}
                                    />
                                </Box>
                                <Typography variant="caption">
                                    Should non-system emails be forwarded to an inbox if a user marks them as Spam?
                                </Typography>

                                {tenancyData.forwardPhish &&
                                    <Box mt={4} mb={1}>
                                        <TextField
                                            variant={"outlined"}
                                            fullWidth
                                            label="Send Email to"
                                            InputLabelProps={{ style: { backgroundColor: "transparent" } }}
                                            type={"email"}
                                            value={tenancyData.reporterEmail}
                                            onChange={(e) => setTenancyData(prevData => ({ ...prevData, reporterEmail: e.target.value }))}
                                        />
                                    </Box>
                                }
                            </MainCard>
                        </Grid>

                        <Grid item xs={6}>
                            <MainCard content={true} boxShadow={true} border={false} sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <Typography variant="h4">
                                        Exchange Permissions
                                    </Typography>
                                </Box>
                                <Box mb={3}>
                                    <Typography variant="caption">
                                        Grant permissions to allow reading and editing of email. This is to enable the function of both email forwarding and deletion, post report.
                                    </Typography>
                                </Box>
                                <Box justifyContent="center" display="flex" flexDirection="column" alignItems="center">
                                    <Button
                                        onClick={startPhishReportAuth}
                                        variant="contained"
                                        color="warning"
                                        sx={{ width: '80%' }}
                                        disabled={isProcessingCallback}
                                    >
                                        {isProcessingCallback ? 'Processing...' : 'Grant permissions in Microsoft'}
                                    </Button>
                                </Box>
                            </MainCard>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                onClick={handleSave}
                                variant="contained"
                                color="warning"
                                sx={{ ml: 2, width: "10%" }}
                                disabled={isProcessingCallback}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={subTabValue} index={1}>
                    <iframe
                        style={{ width: "100%", height: "80vh", border: "none" }}
                        title={`install`}
                        src={'https://boxphishsupport.helpdocs.io/article/dg65nxlbhu-phish-report-button-outlook-direct-install'}
                    />
                </TabPanel>
                <TabPanel value={subTabValue} index={2}>
                    <iframe
                        style={{ width: "100%", height: "80vh", border: "none" }}
                        title={`update`}
                        src={'https://boxphishsupport.helpdocs.io/article/o6c2b9lhuo-updating-phish-report-button'}
                    />
                </TabPanel>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled"
                       sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}