import { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Autocomplete, Box, Button, CircularProgress, Grid, Snackbar, TextField } from "@mui/material";
import { useUpdateUserMutation } from "../../store/services/user-service";
import { useLazyGetLocationsByTenancyIdQuery, useLazyGetCitiesByTenancyIdQuery, useLazyGetCompanyNamesByTenancyIdQuery,
    useLazyGetCountriesByTenancyIdQuery, useLazyGetStatesByTenancyIdQuery, useLazyGetDepartmentsByTenancyIdQuery
} from "../../store/services/tenancy.service";

UserDetails.propTypes = {
    user: PropTypes.object.isRequired,
    tenancyData: PropTypes.object.isRequired,
    tenancyBasicData: PropTypes.object.isRequired
};

export default function UserDetails(props) {
    const { user, tenancyData } = props;
    const [updateUser] = useUpdateUserMutation();

    const [userDetails, setUserDetails] = useState({ ...user });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState(false);
    const [getLocationsByTenancyId, { data: locations }] = useLazyGetLocationsByTenancyIdQuery();
    const [getDepartmentsByTenancyId, { data: departments }] = useLazyGetDepartmentsByTenancyIdQuery();
    const [getCompanyNamesByTenancyId, { data: companyNames }] = useLazyGetCompanyNamesByTenancyIdQuery();
    const [getStatesByTenancyId, { data: states }] = useLazyGetStatesByTenancyIdQuery();
    const [getCountriesByTenancyId, { data: countries }] = useLazyGetCountriesByTenancyIdQuery();
    const [getCitiesByTenancyId, { data: cities }] = useLazyGetCitiesByTenancyIdQuery();

    const handleSave = async () => {
        setLoading(true);
        try {
            await updateUser({ userId: userDetails.id, user: userDetails })
                .unwrap()
                .then(() => {
                    setSnackbarMessage("User updated successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                })
                .catch(() => {
                    setSnackbarMessage("Error occurred while updating user");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                });
        } catch (error) {
            setSnackbarMessage("Error occurred while updating user");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
        setLoading(false);
    };

    const handleAutocompleteChange = (field) => (event, value) => {
        if (typeof value === "string") {
            setUserDetails({
                ...userDetails,
                [field]: value
            });
        } else if (value && value.inputValue) {
            setUserDetails({
                ...userDetails,
                [field]: value.inputValue
            });
        } else {
            setUserDetails({
                ...userDetails,
                [field]: value ? value.name : null
            });
        }
    };

    const filterOptions = (options, params) => {
        const filtered = options.filter((option) => {
            if (params.inputValue) {
                return option.name.toLowerCase().includes(params.inputValue.toLowerCase());
            }

            return option;
        });

        if (params.inputValue !== "") {
            filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`
            });
        }

        return filtered;
    };


    const getOptionLabel = (option) => {
        if (typeof option === "string") {
            return option;
        }

        if (option.inputValue) {
            return option.inputValue;
        }

        return option.name;
    };

    const renderOption = (props, option) => (
        <li {...props}>
            {option.name}
        </li>
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="First Name"
                    value={userDetails.firstName}
                    size="medium"
                    fullWidth
                    onChange={(e) => setUserDetails({ ...userDetails, firstName: e.target.value })}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Last Name"
                    value={userDetails.lastName}
                    size="medium"
                    fullWidth
                    onChange={(e) => setUserDetails({ ...userDetails, lastName: e.target.value })}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Email"
                    value={userDetails.email}
                    size="medium"
                    fullWidth
                    disabled={ true }
                    onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                    sx={{ mb: 2 }}
                />
                {tenancyData && (
                    <>
                        <Box
                            onClick={() => {
                                getLocationsByTenancyId({ tenancyId: tenancyData.tenancyId });
                            }}>
                            <Autocomplete
                                id="location-autocomplete"
                                size="medium"
                                fullWidth
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={userDetails.location}
                                options={locations || []}
                                sx={{ mb: 2 }}
                                onChange={handleAutocompleteChange("location")}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                renderOption={renderOption}
                                renderInput={(params) => <TextField {...params} label="Location" />}
                            />
                        </Box>
                        <Box
                            onClick={() => {
                                getDepartmentsByTenancyId({ tenancyId: tenancyData.tenancyId });
                            }}>
                            <Autocomplete
                                id="department-autocomplete"
                                size="medium"
                                fullWidth
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={userDetails.department}
                                options={departments || []}
                                sx={{ mb: 2 }}
                                onChange={handleAutocompleteChange("department")}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                renderOption={renderOption}
                                renderInput={(params) => <TextField {...params} label="Department" />}
                            />
                        </Box>
                        <Box
                            onClick={() => {
                                getCompanyNamesByTenancyId({ tenancyId: tenancyData.tenancyId });
                            }}>
                            <Autocomplete
                                id="companyName-autocomplete"
                                size="medium"
                                fullWidth
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={userDetails.companyName}
                                options={companyNames || []}
                                sx={{ mb: 2 }}
                                onChange={handleAutocompleteChange("companyName")}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                renderOption={renderOption}
                                renderInput={(params) => <TextField {...params} label="Company Name" />}
                            />
                        </Box>
                        <Box
                            onClick={() => {
                                getCountriesByTenancyId({ tenancyId: tenancyData.tenancyId });
                            }}>
                            <Autocomplete
                                id="country-autocomplete"
                                size="medium"
                                fullWidth
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={userDetails.country}
                                options={countries || []}
                                sx={{ mb: 2 }}
                                onChange={handleAutocompleteChange("country")}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                renderOption={renderOption}
                                renderInput={(params) => <TextField {...params} label="Country" />}
                            />
                        </Box>
                        <Box
                            onClick={() => {
                                getStatesByTenancyId({ tenancyId: tenancyData.tenancyId });
                            }}>
                            <Autocomplete
                                id="state-autocomplete"
                                size="medium"
                                fullWidth
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={userDetails.state}
                                options={states || []}
                                sx={{ mb: 2 }}
                                onChange={handleAutocompleteChange("state")}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                renderOption={renderOption}
                                renderInput={(params) => <TextField {...params} label="State" />}
                            />
                        </Box>
                        <Box
                            onClick={() => {
                                getCitiesByTenancyId({ tenancyId: tenancyData.tenancyId });
                            }}>
                            <Autocomplete
                                id="city-autocomplete"
                                size="medium"
                                fullWidth
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={userDetails.city}
                                options={cities || []}
                                sx={{ mb: 2 }}
                                onChange={handleAutocompleteChange("city")}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                renderOption={renderOption}
                                renderInput={(params) => <TextField {...params} label="City" />}
                            />
                        </Box>
                    </>
                )}

                <Grid item xs={12}>
                    <Button onClick={handleSave} variant="contained" color="warning" sx={{ width: "50%" }}>
                        {loading ? <CircularProgress size={24} color="black" /> : "Update User"}
                    </Button>
                </Grid>
            </Grid>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen}
                      autoHideDuration={3000}
                      onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled"
                       sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};
