
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {  cnAuth } from "../../store/reducers/auth";
import { setTenancyId } from "../../store/reducers/tenancy";
import { dispatch } from "../../store";
import { Box, Grid } from "@mui/material";
import LogoSection from "../../components/Logo";

const CnLogin = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const ssoParams = window.location.hash.substring(1)
        const returnUrl = searchParams.get('returnUrl');
        const idToken = searchParams.get('id_token');
        const state = searchParams.get('state');

        if (!state) {
            throw new Error('Hash is null');
        }

        const storedState = sessionStorage.getItem('State');
        if (storedState !== state) {
            throw new Error('State mismatch');
        }

        dispatch(cnAuth({ token: idToken }))
            .unwrap()
            .then((returned) => {
                if (returned) {
                    dispatch(setTenancyId(returned.user.tenancyId)).then(() => {
                        navigate('/');
                    });
                }
            })
            .catch((err) => {
                navigate('/?error=' + err);
            });

    }, []);
    return (
        <Box sx={{ minHeight: "100vh", bgcolor: "#FFFFFF" }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    minHeight: "100vh"
                }}
            >
                <div className="flex items-center justify-center min-h-screen">
                    <Box sx={{ minHeight: "100vh", bgcolor: "#FFFFFF" }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                minHeight: "100vh"
                            }}
                        >
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                <LogoSection />
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                <div className="flex items-center justify-center min-h-screen">
                                    <div className="text-center">
                                        <h1 className="text-xl mb-4">Authenticating...</h1>
                                        <div
                                            className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full mx-auto"></div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Grid>
        </Box>
    );
}

export default CnLogin;
