import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
    Alert,
    Box,
    Button,
    CircularProgress, FormControl, IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Snackbar,
    Tooltip,
    useMediaQuery
} from "@mui/material";
import Table from "../../components/Table/Table";
import { useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation, useExportUsersMutation } from "../../store/services/user-service";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IosShareIcon from '@mui/icons-material/IosShare';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddUserModal from "./Modal/AddUserModal";
import ImportCsvModal from "./Modal/ImportCsvModal";
import DeleteCsvModal from "./Modal/DeleteCsvModal";
import { useSendTenancyReminderEmailMutation } from "../../store/services/response.service";
import { useCreateOnDemandMutation } from "../../store/services/tenancy.service";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import ConfirmationDialog from "../../utils/confirmationDialog";
import UserEnableDisable from "../../components/Button/UserEnableDisable"


UsersTable.propTypes = {
    type: PropTypes.oneOf(["edit", "select", "filter", "send", "selectOne"]).isRequired,
    userIds: PropTypes.array,
    setUserId: PropTypes.func,
    filterBy: PropTypes.string,
    filterId: PropTypes.string,
    formatId: PropTypes.string,
    format: PropTypes.string,
    CSVButton: PropTypes.shape({
        label: PropTypes.string,
        enabled: PropTypes.bool,
        onClick: PropTypes.func
    })
};

export default function UsersTable(props) {
    const { type, userIds, setUserId, filterBy, filterId, formatId, format, CSVButton} = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const { tenancyId } = useSelector((state) => state.tenancy);

    const lightMode = theme.palette.mode === "light";
    const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
    

    const [exportUsers] = useExportUsersMutation();
    const [deleteUser] = useDeleteUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [sendTenancyReminderEmail] = useSendTenancyReminderEmailMutation();
    const [createOnDemand] = useCreateOnDemandMutation();

    const [serverSearchQuery, setServerSearchQuery] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    const queryParams = useMemo(() => ({
        perPage: paginationModel.pageSize,
        page: paginationModel.page, 
        includes: "",
        query: "",
        searchTerm: serverSearchQuery,
        tenancyId: tenancyId,
        adminFilter: false,
        extendedResults: true
    }), [paginationModel, tenancyId, serverSearchQuery]);

    const {
        data: usersData,
        error: usersError,
        isFetching: usersLoading,
        refetch
    } = useGetUsersQuery(queryParams);

    const getChangedData = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };


    const totalUsers = usersData?.rowsAffected;
    //const totalFilteredUsers = usersData?.filteredCount;

    const [bulkMenuAnchorEl, setBulkMenuAnchorEl] = useState(null);
    const [bulkMenuOpen, setBulkMenuOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [importCsvModalOpen, setImportCsvModalOpen] = useState(false);
    const [deleteCsvModalOpen, setDeleteCsvModalOpen] = useState(false);
    const [isExportingAll, setIsExportingAll] = useState(false);
    const [isExportingActive, setIsExportingActive] = useState(false);
    const [selected, setSelected] = useState([]);
    const [filteredUsersData, setFilteredUsersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSendingReminders, setIsSendingReminders] = useState(false);
    const [userLoading, setUserLoading] = useState({});
    const [statusFilter, setStatusFilter] = useState("All");

    // Confirmation Dialog
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContent, setDialogContent] = useState("");
    const [dialogAction, setDialogAction] = useState(null);

    const [hoveredUserId, setHoveredUserId] = useState(null);

    const handleMouseEnter = (userId) => {
        setHoveredUserId(userId);
    };

    const handleMouseLeave = () => {
        setHoveredUserId(null);
    };

    const renderUserButton = (user) => (
        <UserEnableDisable
            user={user}
            type={type}
            handleEnableChange={handleEnableChange}
            userLoading={userLoading}
        />
    );

    const handleBulkMenuClick = (event) => {
        setBulkMenuAnchorEl(event.currentTarget);
        setBulkMenuOpen(true);
    };

    const handleBulkMenuClose = () => {
        setBulkMenuAnchorEl(null);
        setBulkMenuOpen(false);
    };

    const handleAddUserManually = () => {
        setAddUserModalOpen(true);
    };

    const handleExportUsers = async (onlyActive) => {
        onlyActive ? setIsExportingActive(true) : setIsExportingAll(true);
        try {
            const response = await exportUsers({
                tenancyId,
                onlyActive,
            }).unwrap();

            if (response && response.preSignedUrl) {
                // Create invisible iframe for download instead of window.open()
                const downloadInIframe = (url) => {
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = url;
                    document.body.appendChild(iframe);

                    setTimeout(() => {
                        document.body.removeChild(iframe);
                    }, 2000);
                };

                const checkFileReady = async () => {
                    try {
                        const result = await fetch(response.preSignedUrl, {
                            method: 'GET',
                            headers: { skipInterceptor: 'true' }
                        });
                        if (result.ok) {
                            downloadInIframe(response.preSignedUrl);
                            return true;
                        }
                        return false;
                    } catch (error) {
                        return false;
                    }
                };

                // Poll every 2 seconds for up to 30 seconds
                let attempts = 0;
                while (attempts < 15) {
                    const isReady = await checkFileReady();
                    if (isReady) {
                        setSnackbarMessage("Users exported successfully.");
                        setSnackbarSeverity("success");
                        return;
                    }
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    attempts++;
                }
                throw new Error('Timeout waiting for file to be ready');
            } else {
                throw new Error('Failed to get pre-signed URL');
            }
        } catch (error) {
            console.error(`Error exporting users: ${error}`);
            setSnackbarMessage("Error exporting users.");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            onlyActive ? setIsExportingActive(false) : setIsExportingAll(false);
        }
    };

    const handleExportActiveUsers = () => {
        handleExportUsers(true);
    };

    const handleExportAllUsers = () => {
        handleExportUsers(false);
    };

    const handleImportCsv = () => {
        setImportCsvModalOpen(true);
    };

    const handleDeleteCsv = () => {
        setDeleteCsvModalOpen(true);
    };

    const handleDeleteUsers = async () => {
        setLoading(true);
        setSelected([]);
        setDialogTitle("Delete Selected Users");
        setDialogContent("Are you sure you want to delete the selected users? This action is irreversible.");
        setDialogAction(() => async () => {
            try {
                await Promise.all(selected.map(id => deleteUser({ userId: id }).unwrap()));
                setSnackbarMessage(`${selected.length} user(s) deleted successfully.`);
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setSelected([]);
            } catch (error) {
                setSnackbarMessage("Error deleting users.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
            setLoading(false);
        });
        setDialogOpen(true);
    };

    const bulkMenuId = bulkMenuOpen ? "bulk-actions-menu" : undefined;

    const handleEdit = (user) => {
        if (type === "select") {
            return;
        }

        navigate(`/users/${user.id}`, { state: { user } });
    };

    const handleSendReminders = async () => {
        setIsSendingReminders(true);
        await sendTenancyReminderEmail({ tenancyId: tenancyId }).unwrap().then(() => {
            setSnackbarMessage("Reminders sent successfully.");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(() => {
            setSnackbarMessage("Error sending reminders.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
        setIsSendingReminders(false);
    };

    const handleEnableChange = async (user) => {
        const tempUser = { ...user };
        tempUser.enabled = !tempUser.enabled;
        setUserLoading(prev => ({ ...prev, [user.id]: true }));

        await updateUser({ userId: user.id, user: tempUser }).unwrap().then(() => {
            setSnackbarMessage(`User has been ${tempUser.enabled ? "enabled" : "disabled"}.`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(() => {
            tempUser.enabled = !tempUser.enabled;
            setSnackbarMessage(`Error occurred while ${tempUser.enabled ? "enabling" : "disabling"} user.`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });

        setUserLoading(prev => ({ ...prev, [user.id]: false }));

        setSnackbarOpen(false);
    };

    const handleSend = (user) => {
        const data = {
            TenancyId: tenancyId,
            ScheduleOption: "sendNow",
            ScheduleEmailDateTime: new Date(),
            SendType: format,
            SendTypeName: formatId,
            SendTo: "User",
            SimulationId: formatId,
            SendToId: 0,
            SendToName: user.email,
            SendToUserId: user.id,
            ScheduleDateTimeEnd: new Date(),
            SendingTimeStart: null,
            SendingTimeEnd: null,
            PauseWeekends: false,
            StopSending: false
        };

        createOnDemand({ data, tenancyId }).unwrap().then(() => {
            setSnackbarMessage(`Successfully sent content: ${formatId} to user: ${user.email}`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(() => {
            setSnackbarMessage(`Failed to send content: ${formatId} to user: ${user.email}`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    let headCells = [
        {
      id: "select",
      label: "Select",
      render: (dataRow) => {
        const isSelected = type === "selectOne" 
          ? userIds.length === 1 && userIds[0].id === dataRow.id
          : userIds.some(user => user.id === dataRow.id);
        return (
          <IconButton
            size="small"
            onClick={() => {
              if (type === "selectOne") {
                if (isSelected) {
                  setUserId([]);
                } else {
                  setUserId([dataRow]);
                }
              } else {
                if (isSelected) {
                  setUserId(userIds.filter(user => user.id !== dataRow.id));
                } else {
                  setUserId([...userIds, dataRow]);
                }
              }
            }}
          >
            {isSelected ? <CheckBoxRoundedIcon color="warning" /> : <CheckBoxOutlineBlankRoundedIcon color={lightMode ? "black" : "white"} />}
          </IconButton>
        );
      },
      initialVisible: type === "select" || type === "selectOne"
    },
        { id: "role", label: "Role", initialVisible: false },
        {
            id: "active",
            label: "Status",
            dataKey: "enabled",
            render: renderUserButton,
            initialVisible: true
        },
        {
            id: "edit",
            label: "Edit",
            render: (dataRow) => (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(dataRow)}
                    sx={{ textTransform: "capitalize" }}
                >
                    View
                </Button>
            ),
            initialVisible: type === "edit"
        },
        { id: "firstName", label: "First name", initialVisible: true },
        { id: "lastName", label: "Last name", initialVisible: true },
        { id: "email", label: "Email", initialVisible: true },
        { id: "department", label: "Department", initialVisible: true },
        { id: "location", label: "Location", initialVisible: true },
        {
            id: "dateCreated",
            label: "Date created",
            initialVisible: false,
            render: (dataRow) => {
                // Convert the ISO date string to dd/mm/yyyy format
                const date = new Date(dataRow.dateCreated);
                return date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        { id: "city", label: "City", initialVisible: false },
        { id: "state", label: "State", initialVisible: false },
        { id: "country", label: "Country", initialVisible: false },
        { id: "companyName", label: "Company name", initialVisible: false }
    ];

    const toolbarActions = [
        {
            render: () => (
                <Box>
                    {type === "edit" && (
                        <>
                            <FormControl>
                                <InputLabel style={{ backgroundColor: "transparent" }}>Status</InputLabel>
                                <Select
                                    variant="outlined"
                                    size="small"
                                    sx={{ mr: 2, minWidth: 120 }}
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    label="Status"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Enabled">Enabled</MenuItem>
                                    <MenuItem value="Disabled">Disabled</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: "capitalize", mr: 1 }}
                                aria-controls={bulkMenuId}
                                aria-haspopup="true"
                                onClick={handleBulkMenuClick}
                                endIcon={<AddIcon />}
                            >
                                Bulk actions
                            </Button>
                            <Menu
                                id={bulkMenuId}
                                anchorEl={bulkMenuAnchorEl}
                                open={bulkMenuOpen}
                                onClose={handleBulkMenuClose}
                                MenuListProps={{
                                    "aria-labelledby": "add-user-button",
                                    sx: { bgcolor: "background.default" }
                                }}
                            >
                                <MenuItem onClick={handleExportActiveUsers} disabled={isExportingActive}>
                                    <IosShareIcon sx={{ mr: 1 }} />
                                    {isExportingActive ? 'Exporting...' : 'Export Active Users'}
                                </MenuItem>
                                <MenuItem onClick={handleExportAllUsers} disabled={isExportingAll}>
                                    <IosShareIcon sx={{ mr: 1 }} />
                                    {isExportingAll ? 'Exporting...' : 'Export All Users'}
                                </MenuItem>
                                <MenuItem onClick={handleSendReminders} disabled={isSendingReminders}>
                                    {isSendingReminders ? (
                                        <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                    ) : (
                                        <NotificationsNoneIcon sx={{ mr: 1 }} />
                                    )}
                                    {isSendingReminders ? "Sending..." : "Send Reminders"}
                                </MenuItem>

                                <MenuItem onClick={handleImportCsv}>
                                    <SystemUpdateAltIcon sx={{ mr: 1 }} />
                                    Add Using CSV
                                </MenuItem>
                                <MenuItem onClick={handleDeleteCsv}>
                                    <DeleteOutlineIcon sx={{ mr: 1 }} />
                                    Delete Using CSV
                                </MenuItem>
                            </Menu>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ ml: 1 }}
                                onClick={handleAddUserManually}
                                endIcon={<AddIcon />}
                            >
                                Add User
                            </Button>
                            <Tooltip title="Select users to delete" placement="top" arrow>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="warning"
                                    sx={{ ml: 2 }}
                                    onClick={handleDeleteUsers}
                                    disabled={selected.length === 0 && !loading}
                                >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : "Delete"}
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </Box>
            )
        }
    ];

    if (type === "send") {
        headCells.push({
            id: "send",
            label: "Send",
            render: (dataRow) => (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSend(dataRow)}
                >
                    Send to User
                </Button>
            ),
            initialVisible: true
        });
    }

    useEffect(() => {
        if (type === "selectOne" && selected.length > 1) {
            const [firstSelected] = selected;
            setSelected([firstSelected]);
            setUserId(userIds.filter(user => user.id === firstSelected));
        }
    }, [type, selected, setUserId, userIds]);

    useEffect(() => {
        if (type === "filter" && usersData) {
            setFilteredUsersData(usersData?.data.filter(user => user[filterBy] === parseInt(filterId)));
        }
    }, [usersData, filterBy, filterId, type]);

    return (
        <>
            <Table
                name={"Users"}
                data={type === "filter" ? filteredUsersData : statusFilter === "All" ? usersData?.data : statusFilter === "Enabled" ? usersData?.data.filter(user => user.enabled) : usersData?.data.filter(user => !user.enabled)}
                error={usersError}
                loading={usersLoading}
                headCells={headCells}
                search={true}
                editColumn={type === "edit"}
                checkbox={type === "edit"}
                onCellClick={handleEdit}
                toolbarActions={!matchDownMD ? toolbarActions : []}
                showToolbarActions={true}
                onDelete={handleDeleteUsers}
                selected={selected}
                setSelected={setSelected}
                maxTableHeight={type === "select" ? "calc(100vh - 400px)" : "calc(100vh - 320px)"}
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                count={totalUsers}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                isServerSidePagination={true} //server side pagination removed to fix searching functionality
                getChangedData={getChangedData}
                serverSearchQuery={serverSearchQuery}
                setServerSearchQuery={setServerSearchQuery}
                //CSVButton={CSVButton} remove CSV button from users table to be replaced in the sims and courses table individually
                plural={'users'}
                enableSorting={false}
            />

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                      onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <AddUserModal
                open={addUserModalOpen}
                onClose={() => setAddUserModalOpen(false)}
            />
            <ImportCsvModal
                open={importCsvModalOpen}
                onClose={() => {
                    setImportCsvModalOpen(false);
                    handleBulkMenuClose();
                }}
            />
            <DeleteCsvModal
                open={deleteCsvModalOpen}
                onClose={() => {
                    setDeleteCsvModalOpen(false);
                    handleBulkMenuClose();
                }}
            />
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContent}
                onConfirm={() => {
                    setDialogOpen(false);
                    dialogAction();
                }}
                onCancel={() => setDialogOpen(false)}
            />
            </>
    );
}
