import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar } from "@mui/material";
import Drawer from "./Drawer";
import Header from "./Header";
import { openDrawer } from "store/reducers/menu";
import { useDynamicBreakpoints } from "../../utils/breakpointUtils";

const MainLayout = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem("user"));
    const isUser = user && user?.role === "";
    const { drawerOpen } = useSelector((state) => state.menu);
    const { isLoggedIn } = useSelector((state) => state.auth);

    const { matchDownSM, matchDownMD, matchDownLG } = useDynamicBreakpoints(drawerOpen);

    const handleDrawerToggle = () => {
        dispatch(openDrawer({ drawerOpen: !drawerOpen }));
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800 && drawerOpen) {
                dispatch(openDrawer({ drawerOpen: false }));
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [drawerOpen, dispatch]);

    return (
        <Box sx={{ display: "flex", width: "100%", bgcolor: 'background.default' }}>
            <Header open={drawerOpen} />
            {<Drawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />}
            <Box component="main" sx={{ width: drawerOpen ? "calc(100% - 260px)" : "100%", flexGrow: 1, p: { xs: 2, sm: 3 }, mt: -2}}>
                <Toolbar
                    sx={{
                        minHeight: '50px !important',
                        height: '50px',
                    }}
                />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;