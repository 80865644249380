import React from 'react';
import { Box, Switch, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingSwitch = ({ checked, onChange, disabled, loading, inputProps }) => {
    const handleChange = (event) => {
        event.preventDefault();
        if (!loading) {
            onChange();
        }
    };

    return (
        <Box
            sx={{
                width: 58,
                height: 38,
                position: 'relative',
                transform: 'translateX(0)',
                willChange: 'transform'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Switch
                    checked={checked}
                    onClick={handleChange}
                    disabled={disabled || loading}
                    inputProps={inputProps}
                    color="primary"
                    sx={{
                        transform: 'translateZ(0)',
                        '& .MuiSwitch-switchBase': {
                            transition: 'none'
                        }
                    }}
                />
            </Box>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: checked ? '28.5px' : '8.5px',
                        height: 20,
                        width: 20,
                        transform: 'translate(0, -52.5%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pointerEvents: 'none',
                        transition: 'left 150ms cubic-bezier(0.4, 0, 0.2, 1)',
                        willChange: 'transform'
                    }}
                >
                    <CircularProgress
                        size={14}
                        color="primary"
                    />
                </Box>
            )}
        </Box>
    );
};

LoadingSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    inputProps: PropTypes.object,
};

export default LoadingSwitch;