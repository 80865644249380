// ==============================|| THEME CONFIG  ||============================== //

const config = {
    defaultPath: '/',
    fontFamily: `'Inter', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr',
};

export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;
export const BRAND_TYPE = process.env.REACT_APP_BRAND_TYPE;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const API_DOC_URL = process.env.REACT_APP_APIDOCS_URL;

export const JOURNEY_API_URL = process.env.REACT_APP_JOURNEY_API_URL;
export const ONDEMAND_API_URL = process.env.REACT_APP_ONDEMAND_API_URL;
export const QUICKSIGHT_API_URL = process.env.REACT_APP_QUICKSIGHT_API_URL;
export const RESPONSE_API_URL = process.env.REACT_APP_RESPONSE_API_URL;
export const SCHEDULE_API_URL = process.env.REACT_APP_SCHEDULE_API_URL;
export const SIMULATION_API_URL = process.env.REACT_APP_SIMULATION_API_URL;
export const TENANCY_API_URL = process.env.REACT_APP_TENANCY_ENDPOINT_URL;
export const TENANCY_REPORTING_API_URL = process.env.REACT_APP_TENANCY_REPORTING_API_URL;
export const UNIVERSITY_API_URL = process.env.REACT_APP_UNIVERSITY_API_URL;


export const GOOGLE_CLIENT_CODE = process.env.REACT_APP_GOOGLE_CLIENT_CODE;
export const GOOGLE_CLIENT_CODE_IMPORT = process.env.REACT_APP_GOOGLE_CLIENT_CODE_IMPORT;

export const OFFICE_CLIENT_CODE = process.env.REACT_APP_OFFICE_CLIENT_CODE;
export const OFFICE_CLIENT_CODE_IMPORT = process.env.REACT_APP_OFFICE_CLIENT_CODE_IMPORT;
export const OFFICE_CLIENT_CODE_SYNC = process.env.REACT_APP_OFFICE_CLIENT_CODE_SYNC;
export const OFFICE_PHISH_REPORT_CODE = process.env.REACT_APP_OFFICE_PHISH_REPORT_CODE;


export const SEND_FROM_ADDRESSES = process.env.REACT_APP_SEND_FROM_ADDRESSES;
export const SIMULATION_CATEGORIES = process.env.REACT_APP_SIMULATION_CATEGORIES;
export const COURSE_CATEGORIES = process.env.REACT_APP_COURSE_CATEGORIES;
export const SIM_AND_COURSE_FORMATS = process.env.REACT_APP_SIM_AND_COURSE_FORMATS;
export const SIMS_ADDRESSES = process.env.REACT_APP_SIMS_ADDRESSES;
export const CN_LOGIN = process.env.REACT_APP_CN_LOGIN_ENDPOINT


export default config;
export const drawerWidth = 260;
