// assets
import { ReactComponent as Home } from '../assets/navigation-icons/home.svg';
import { ReactComponent as Users } from '../assets/navigation-icons/users.svg';
import { ReactComponent as Tenancy } from '../assets/navigation-icons/tenancy.svg';
import { ReactComponent as Settings } from '../assets/navigation-icons/settings.svg';
import { ReactComponent as OnDemand } from '../assets/navigation-icons/on-demand.svg';
import { ReactComponent as Report } from '../assets/navigation-icons/report.svg';
import { ReactComponent as Help } from '../assets/navigation-icons/help.svg';

// icons
const icons = {
    Home,
    Users,
    Tenancy,
    OnDemand,
    Report,
    Help,
    Settings
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //
const environment = process.env.REACT_APP_BRAND_TYPE === 'censornet'

const tenancyAdminItems = {
    id: 'tenancyAdminItems',
    type: 'group',
    divider: true,
    children: [
        {
            id: 'users1',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.Users,
        },
        {
            id: 'tenancy1',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.Settings,
        },
        {
            id: 'ondemand1',
            title: 'On Demand',
            type: 'item',
            url: '/on-demand',
            icon: icons.OnDemand,
        },
        {
            id: 'reports1',
            title: 'Reports',
            type: 'item',
            url: '/reports',
            icon: icons.Report,
        },
        {
            id: 'help',
            title: 'Help',
            type: 'item',
            url: environment ? 'https://help.clouduss.com/sat-user-guide/sat-new-interface-user-guide' : 'https://boxphishsupport.helpdocs.io/',
            target: '_blank',
            icon: icons.Help,
        },
    ]
};

export default tenancyAdminItems;
