import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { TENANCY_API_URL } from 'config';
const register = (name, email, password, passwordConfirm, company) => {
    return axios.post(TENANCY_API_URL + 'auth/register/create', {
        name,
        email,
        password,
        passwordConfirm,
        company,
        'verified': false,
        'role': 3
    });
};

const login = (email, password) => {
    return axios
        .post(TENANCY_API_URL + '/auth', {
            username: email,
            password: password
        })
        .then((response) => {
            if (response.data.token) {
                return processAuthResponse(response.data);
            }
            return null;
        });
};

// Helper function to process authentication response consistently
const processAuthResponse = (data) => {
    const decodedToken = jwtDecode(data.token);
    const userdata = JSON.parse(decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']);

    const userData = {
        token: data.token,
        role: decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
        id: decodedToken.UserId,
        email: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
        tenancyId: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'],
        firstName: data.firstName,
        lastName: data.lastName,
        enableReporting: userdata.enableReporting === 'True',
        enableOndemand: userdata.enableOndemand === 'True',
        reportingLevel: userdata.reportingLevel,
        onDemandLevel: userdata.onDemandLevel,
    };

    localStorage.setItem('user', JSON.stringify(userData));
    clearAuthCookies();

    return userData;
};

const clearAuthCookies = () => {
    document.cookie = "access_token =; Max-Age=0";
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

const ssoLogin = (accessToken) => {
    return axios
        .get(TENANCY_API_URL + '/ssoauth?' + accessToken)
        .then((response) => {
            if (response.data.token) {
                return processAuthResponse(response.data);
            }
            return null;
        });
};

const googleSsoLogin = (accessToken) => {
    return axios
        .get(TENANCY_API_URL + '/googlessoauth?' + accessToken)
        .then((response) => {
            if (response.data.token) {
                return processAuthResponse(response.data);
            }
            return null;
        });
};

const cnLogin = (accessToken) => {
    return axios
        .get(TENANCY_API_URL + '/cnssoauth?access_token=' + accessToken)
        .then((response) => {
            if (response.data.token) {
                return processAuthResponse(response.data);
            }
            return null;
        });
};

const logout = () => {
    localStorage.removeItem('user');

    return axios
        .post(TENANCY_API_URL + `/logout`, {})
        .then(() => {
            console.log("Logged out");
        });
};


const forgot = (email, siteUrl) => {
    email = encodeURIComponent(email)
    siteUrl = encodeURIComponent(siteUrl);
    return axios
        .post(TENANCY_API_URL + `/auth/forgot-password?emailAddress=${email}&siteUrl=${siteUrl}`, {})
        .then((response) => {
            return response.data;
        });
};

const resetPassword = (email, token, newPassword) => {
    const encodedEmail = encodeURIComponent(email);
    return axios.post(TENANCY_API_URL + '/auth/reset-password', {
        emailAddress: encodedEmail,
        token,
        newPassword
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log('Error response:', error.response);
        throw error;
    });
};

const isLoggedIn = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        const decodedToken = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
            return true;
        } else {
            logout();
            return false;
        }
    }
    return false;
};

const authService = {
    register,
    login,
    logout,
    forgot,
    resetPassword,
    isLoggedIn,
    ssoLogin,
    GoogleSsoLogin: googleSsoLogin,
    cnLogin
};

export default authService;
