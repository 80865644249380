import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useGetCoursesQuery, useUploadToS3Mutation, useUploadCourseCsvMutation } from "../../store/services/university-service";
import Table from "../../components/Table/Table";
import { Alert, Autocomplete, Button, IconButton, Modal, Snackbar, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { useNavigate } from "react-router-dom";
import UsersTable from "../users/UsersTable";
import AddCourseModal from "../on-demand/CreateOnDemand/AddCourseModal";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CoursePreview from "./CoursePreview";
import CourseEdit from "./CourseEdit";
import { useTheme } from "@mui/material/styles";

const userModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    boxShadow: 24,
    borderRadius: 4,
    bgcolor: "background.paper"
};

CourseTable.propTypes = {
    type: PropTypes.string.isRequired,
    courseIds: PropTypes.array,
    setCourseId: PropTypes.func,
    onRandomSelect: PropTypes.func
};

export default function CourseTable(props) {
    const { type, courseIds = [], setCourseId, onRandomSelect } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const { tenancyId } = useSelector((state) => state.tenancy);
    const additionalQueryParams = type === "edit" ? "" : `&published=true&tenancyId=${tenancyId}`;
    const [isAdmin, setIsAdmin] = useState(false);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    const { data: coursesData, isLoading: coursesLoading, error: coursesError } = useGetCoursesQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page,
        includes: "",
        query: "",
        additionalQueryParams: additionalQueryParams
    });

    const [uploadToS3] = useUploadToS3Mutation();
    const [uploadCourseCsv] = useUploadCourseCsvMutation();

    const [courseTypes, setCourseTypes] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [courseLanguage, setCourseLanguage] = useState(null);
    const fileInputRef = useRef(null);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [addCourseModalOpen, setAddCourseModalOpen] = useState(false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);

    useEffect(() => {
        if (coursesData) {
            const types = coursesData.map((course) => course.languageCode);
            const uniqueTypes = [...new Set(types)];
            setCourseTypes(uniqueTypes);
        }
    }, [coursesData]);

    const handleCourseTypeChange = (event, value) => {
        if (value) {
            setCourseLanguage(value);
            const filtered = coursesData.filter((course) => course.languageCode === value);
            setFilteredCourses(filtered);
        } else {
            setCourseLanguage(null);
            setFilteredCourses([]);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleSendCourseUsingCSV(file);
        }
    };

    const handleSendCourseUsingCSV = async (file) => {
        if (file.type === "application/vnd.ms-excel" || file.type === "text/csv") {
            try {
                const response = await uploadCourseCsv({ file }).unwrap();
                setSnackbarMessage(response.success ?
                    "Upload successful" :
                    "Error uploading CSV");
                setSnackbarSeverity(response.success ? "success" : "error");
                setSnackbarOpen(true);
            } catch (error) {
                console.error("Error uploading CSV:", error);
                setSnackbarMessage(error.data || "Error uploading CSV");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage("Invalid file type. Please upload a CSV file.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };
    /*
    const handleSendUsingCSV = async (file) => {
        if (file.type === "application/vnd.ms-excel" || file.type === "text/csv") {
            await uploadToS3({ tenancyId: tenancyId, file: file }).unwrap().then((response) => {
                    console.log("Upload to S3 response:", response);
                    if (response.success) {
                        setSnackbarMessage("Users uploaded successfully.");
                        setSnackbarSeverity("success");
                    } else {
                        setSnackbarMessage("Error uploading users.");
                        setSnackbarSeverity("error");
                    }
                    setSnackbarOpen(true);
                }
            ).catch((error) => {
                console.log("Error uploading to S3:", error);
                setSnackbarMessage("Error uploading users.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
        } else {
            setSnackbarMessage("Invalid file type. Please upload a CSV file.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };
    */

    const handleAddCourse = () => {
        if (type === "edit") {
            navigate("/courses/add");
        } else {
            setAddCourseModalOpen(true);
        }
    };

    const handleViewCourse = (course) => {
        navigate(`/courses/${course.internalId}`);
    };

    const handleSelectCourse = (course) => {
        setCourseId(prevSelected => {
            const isAlreadySelected = prevSelected.some(c => c.id === course.id);
            if (isAlreadySelected) {
                return prevSelected.filter(c => c.id !== course.id);
            } else {
                return [...prevSelected, course];
            }
        });
    };

    const handlePreviewCourse = (course) => {
        setSelectedCourseId(course.id);
        setPreviewModalOpen(true);
    };

    const handleClosePreviewModal = () => {
        setPreviewModalOpen(false);
        setSelectedCourseId(null);
    };

    const handleOpenEditModal = (course) => {
        setSelectedCourseId(course.internalId);
        setEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setSelectedCourseId(null);
    };

    const handleSaveCourse = async (savedCourse) => {
        handleCloseEditModal();
    };

    const handleRandomSelection = () => {
        if (coursesData) {
            const randomCourses = coursesData.filter(course => course.randomSend);
            if (randomCourses.length > 0) {
                setCourseId(randomCourses);
                setSnackbarMessage(`Selected ${randomCourses.length} course(s) with randomSend enabled.`);
                setSnackbarSeverity("success");
                setSnackbarOpen(true);

                // Call the onRandomSelect prop to navigate to the next step
                if (onRandomSelect) {
                    onRandomSelect();
                }
            } else {
                setSnackbarMessage("No courses with randomSend enabled.");
                setSnackbarSeverity("info");
                setSnackbarOpen(true);
            }
        }
    };

    const headCells = [
        {
            id: "select",
            label: "Select",
            render: (dataRow) => {
                const isSelected = Array.isArray(courseIds) && courseIds.some(course => course.id === dataRow.id);
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (isSelected) {
                                setCourseId(courseIds.filter(course => course.id !== dataRow.id));
                            } else {
                                setCourseId([...courseIds, dataRow]);
                            }
                        }}
                    >
                        {isSelected ? <CheckBoxRoundedIcon color="warning" /> : <CheckBoxOutlineBlankRoundedIcon/>}
                    </IconButton>
                );
            },
            initialVisible: type === "select"
        },
        {
            id: "preview",
            label: "Preview",
            render: (dataRow) => (
                <Button
                    size="small"
                    onClick={() => handlePreviewCourse(dataRow)}
                    aria-label="preview course"
                    variant="contained"
                    color="warning"
                >Preview
                </Button>
            ),
            initialVisible: true
        },
        {
            id: "edit",
            label: "Edit",
            render: (dataRow) => (
                <Button
                    size="small"
                    onClick={() => handleOpenEditModal(dataRow)}
                    aria-label="edit course"
                    variant="contained"
                    color="secondary"
                >Edit
                </Button>
            ),
            initialVisible: type === "edit"
        },
        {
            id: "send-user",
            label: type === "select" ? "Send User" : " ",
            render: (dataRow) => (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenUserModal(dataRow.id)}
                >
                    send to user
                </Button>
            ),
            initialVisible: type === "edit"
        },
        {
            id: "courseName",
            label: "Course Name",
            initialVisible: true
        },
        {
            id: "languageCode",
            label: "Language",
            initialVisible: true
        },
        {
            id: 'format',
            label: 'Format',
            initialVisible: true
        },
        {
            id: "published",
            label: "Published",
            render: (dataRow) => dataRow.published ? "Yes" : dataRow.tenancyId === 0 ? "No" : dataRow.tenancyId,
            initialVisible: type === "edit"
        },
    ];

    const toolbarActions = [{
        render: () => (
            <Box width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                <Autocomplete
                    id={"simulation-type"}
                    disableClearable={!courseLanguage}
                    sx={{ mr: 4, width: 120 }}
                    size="small"
                    multiple={false}
                    value={courseLanguage || "All"}
                    options={courseTypes}
                    onChange={handleCourseTypeChange}
                    renderInput={(params) => <TextField {...params} label="Language" />}
                />
                {type === "select" && isAdmin && (
                    <Button
                        size="medium"
                        variant="contained"
                        color="warning"
                        sx={{ textTransform: "capitalize", mr: 2 }}
                        onClick={handleRandomSelection}
                        endIcon={<ShuffleIcon />}
                    >
                        Random
                    </Button>
                )}
                {type === 'edit' && (
                    <Button
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        onClick={handleAddCourse}
                        endIcon={<AddIcon />}
                    >
                        Add Course
                    </Button>
                )}
                {type !== "select" && isAdmin && (
                    <>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={handleFileChange}
                        />
                        <Button
                            size="medium"
                            variant="contained"
                            color="secondary"
                            sx={{ bgcolor: (theme) => theme.palette.secondary.lighter, ml: 2, }}
                            onClick={() => fileInputRef.current.click()}
                        >
                            send using csv
                        </Button>
                    </>
                )} 
            </Box>
        )
    }];

    const handleCloseUserModal = () => {
        setUserModalOpen(false);
    };

    const handleOpenUserModal = (simulationId) => {
        setSelectedUserId(simulationId);
        setUserModalOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Table
                name={"Courses"}
                data={filteredCourses.length > 0 ? filteredCourses : coursesData}
                error={coursesError}
                loading={coursesLoading}
                headCells={headCells}
                search={true}
                editColumn={false}
                checkbox={false}
                showDelete={false}
                toolbarActions={toolbarActions}
                showToolbarActions={true}
                onDelete={() => console.log("Delete Courses Not Allowed")}
                onCellClick={handleViewCourse}
                selected={courseIds}
                setSelected={handleSelectCourse}
                maxTableHeight={type === "select" ? "calc(100vh - 350px)" : "calc(100vh - 250px)"}
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Modal
                open={userModalOpen}
                onClose={handleCloseUserModal}
                aria-labelledby="user-selection-modal"
                aria-describedby="user-selection-description"
            >
                <Box sx={userModalStyle}>
                    <UsersTable
                        type={"send"}
                        userId={selectedUserId}
                        setUserId={setSelectedUserId}
                        formatId={selectedUserId}
                        format={"course"}
                        CSVButton
                    />
                </Box>
            </Modal>

            <Modal
                open={previewModalOpen}
                onClose={handleClosePreviewModal}
                aria-labelledby="course-preview-modal"
                aria-describedby="course-preview-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                    width: '50%',
                    height: '90vh',
                    p: 2,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    maxHeight: '90vh',
                    overflow: 'auto',
                }}>
                    {selectedCourseId && <CoursePreview courseId={selectedCourseId} />}
                </Box>

            </Modal>

            <Modal
                open={editModalOpen}
                onClose={handleCloseEditModal}
                aria-labelledby="course-edit-modal"
                aria-describedby="course-edit-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    borderRadius: '8px',
                    bgcolor: 'background.default',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflow: 'auto',
                }}>
                    {selectedCourseId && (
                        <CourseEdit
                            courseId={selectedCourseId}
                            onSave={handleSaveCourse}
                            onClose={handleCloseEditModal}
                        />
                    )}
                </Box>
            </Modal>

            <AddCourseModal open={addCourseModalOpen} handleClose={() => setAddCourseModalOpen(false)} />
        </>
    );
}