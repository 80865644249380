import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { APP_URL } from "../../config";
import { useSearchParams } from 'react-router-dom';
import { useGetCampaignResponseQuery } from '../../store/services/simulation-service';

const CaughtYou = () => {
    const [searchParams] = useSearchParams();
    const identifier = searchParams.get('identifier');
    const [landingPageContent, setLandingPageContent] = useState('');

    const { data, isLoading, error } = useGetCampaignResponseQuery(identifier, {
        skip: !identifier
    });

    useEffect(() => {
        if (data?.landingPage) {
            let content = data.landingPage;
            setLandingPageContent(content);
        }
    }, [data]);

    if (isLoading) {
        return (
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50vh"
            }}>
                Loading...
            </Box>
        );
    }

    if (error || !landingPageContent) {
        return (
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50vh"
            }}>
                <img
                    src={`${APP_URL}/assets/img/landingpage/landing-page.png`}
                    alt="Placeholder"
                    width="80%"
                    className="mx-auto"
                />
            </Box>
        );
    }

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            bgcolor: "background.default",
            p: 4,
            minHeight: "50vh"
        }}>
            <Box
                className="landing-page-content"
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    margin: "0 auto",
                    "& img": {
                        maxWidth: "100%",
                        height: "auto"
                    }
                }}
                dangerouslySetInnerHTML={{ __html: landingPageContent }}
            />
        </Box>
    );
};

export default CaughtYou;