import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import PageNotFound from "../pages/extra-pages/PageNotFound";
import TakeCourse from "../pages/landing-page/TakeCourse";
import CaughtYou from "../pages/landing-page/CaughtYou";
import TwoStageLandingPage from "../pages/landing-page/TwoStageLandingPage";
import UssLogin from "../pages/landing-page/UssLogin";
import OutlookPluginHandler from "../pages/tenancy/outlookPluginHandler";
import CnLogin from "../pages/authentication/CnLogin";

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const SsoLogin = Loadable(lazy(() => import('pages/authentication/SsoLogin')));
const GoogleSsoLogin = Loadable(lazy(() => import('pages/authentication/GoogleSsoLogin')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const AliasLogin = Loadable(lazy(() => import('pages/authentication/AliasLogin')));


// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'forgot-password',
            element: <ForgotPassword />
        },
        {
            path: 'login/reset-password',
            element: <ResetPassword />
        },
        {
            path: 'sso/*',
            element: <SsoLogin />
        },

        {
            path: 'google-sso/*',
            element: <GoogleSsoLogin />
        },
        {
            path: 'outlookPluginHandler',
            element: <OutlookPluginHandler />
        },
        {
            path: 'dylan',
            element: <ForgotPassword />
        },
        {
            path: "/universitycourse/takecourse/*",
            element: <TakeCourse />
        },
        {
            path: "/campaignclicked/*",
            element: <CaughtYou />
        },
        {
            path: "/landingpage/*",
            element: <TwoStageLandingPage />
        },
        {
            path: "/aliaslogin",
            element: <AliasLogin />
        },
        {
            path:"uss-login",
            element: <UssLogin />
        },
        {
            path:"cn-sso",
            element: <CnLogin />
        },
        {
            path: '*',
            element: <PageNotFound />
        },
    ]
};

export default LoginRoutes;
