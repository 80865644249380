import { useEffect, useState, useRef } from "react";
import { Modal, Box, TextField, Button, Typography, Grid, Alert, CircularProgress, Autocomplete, IconButton, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useAddUserMutation } from "../../../store/services/user-service";
import { useSelector } from "react-redux";
import {
    useGetCitiesByTenancyIdQuery,
    useGetCompanyNamesByTenancyIdQuery,
    useGetCountriesByTenancyIdQuery,
    useGetDepartmentsByTenancyIdQuery,
    useGetLocationsByTenancyIdQuery,
    useGetStatesByTenancyIdQuery
} from "../../../store/services/tenancy.service";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

AddUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default function AddUserModal(props) {
    const { open, onClose } = props;
    const theme = useTheme();
    const { tenancyId } = useSelector((state) => state.tenancy);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("");
    const [department, setDepartment] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [sendEmailTo, setSendEmailTo] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [countdown, setCountdown] = useState(3);
    const countdownRef = useRef(null);

    const [addUser] = useAddUserMutation();
    // TODO: If we have all of the users, we should probably loop through the users and get a unique list of departments, locations, etc.
    // So that we don't need to run 6 API calls here
    const { data: departments } = useGetDepartmentsByTenancyIdQuery({ tenancyId });
    const { data: locations } = useGetLocationsByTenancyIdQuery({ tenancyId });
    const { data: cities } = useGetCitiesByTenancyIdQuery({ tenancyId });
    const { data: companyNames } = useGetCompanyNamesByTenancyIdQuery({ tenancyId });
    const { data: countries } = useGetCountriesByTenancyIdQuery({ tenancyId });
    const { data: states } = useGetStatesByTenancyIdQuery({ tenancyId });

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const resetForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setLocation("");
        setDepartment("");
        setSendEmailTo("");
        setCompanyName("");
        setCountry("");
        setState("");
        setCity("");
        setFirstNameError(false);
        setEmailError(false);
        setSuccess(false);
        setErrorMessage("");
        setCountdown(3);
    };

    const handleSave = () => {
        let hasError = false;

        if (!firstName) {
            setFirstNameError(true);
            hasError = true;
        }

        if (!email) {
            setEmailError(true);
            hasError = true;
        }

        if (!validateEmail(email)) {
            setEmailError(true);
            setErrorMessage("Please enter a valid email address");
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
            return;
        }

        if (hasError) {
            setErrorMessage("Please fill in all required fields");
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
            return;
        }

        const user = {
            firstName,
            lastName,
            userName: email,
            location: location?.name || location,
            department: department?.name || department,
            city: city?.name || city,
            country: country?.name || country,
            state: state?.name || state,
            companyName: companyName?.name || companyName,
            startUserJourneyOn: new Date().toISOString(),
            tenancyId
        };

        setLoading(true);

        addUser({ user }).unwrap().then(() => {
            setLoading(false);
            setSuccess(true);
            setErrorMessage("");
            setCountdown(3);
            handleCloseWithCountdown();
        }).catch((error) => {
            setErrorMessage("Error adding user: " + error.message);
            setLoading(false);
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
        });
    };

    const handleCloseWithCountdown = () => {
        countdownRef.current = setInterval(() => {
            setCountdown((prev) => {
                if (prev === 1) {
                    clearInterval(countdownRef.current);
                    onClose();
                    setSuccess(false);
                }

                return prev - 1;
            });
        }, 1000);
    };

    const handleAddAnotherUser = () => {
        clearInterval(countdownRef.current);
        resetForm();
        setSuccess(false);
    };

    useEffect(() => {
        if (!open) {
            resetForm();
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="add-user-modal-title"
            aria-describedby="add-user-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 650,
                    boxShadow: theme.customShadows.z1,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.borderRadius.sm,
                    p: "26px 32px"
                }}
            >
                {success ? (
                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} m={4}>
                        <CheckCircleIcon sx={{ color: "primary.main", fontSize: 80, mb: 2 }} />
                        <Typography variant="h5">User added successfully</Typography>

                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={4} width={"100%"}>
                            <Button
                                sx={{ width: "100%", mr: 2 }}
                                variant="contained"
                                color="secondary"
                                onClick={handleAddAnotherUser}
                            >
                                Add Another User
                            </Button>
                            <Button
                                sx={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={handleCloseWithCountdown}
                            >
                                Close ({countdown})
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
                            <Typography id="add-user-modal-title" variant="h4" >
                                Add User
                            </Typography>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider sx={{ mb: 3 }} />
                        {errorMessage && (
                            <Alert severity="error" sx={{ mb: 2 }} onClose={() => setErrorMessage("")}>
                                {errorMessage}
                            </Alert>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        setFirstNameError(!e.target.value);
                                    }}
                                    onBlur={() => setFirstNameError(!firstName)}
                                    required
                                    error={firstNameError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(!e.target.value);
                                    }}
                                    onBlur={() => setEmailError(!email)}
                                    required
                                    error={emailError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Send Email To"
                                    value={sendEmailTo}
                                    onChange={(e) => setSendEmailTo(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="location"
                                    size="small"
                                    fullWidth
                                    freeSolo
                                    options={locations || []}
                                    value={location}
                                    getOptionLabel={(option) => typeof option === "string" ? option : option.name}
                                    onChange={(event, newValue) => setLocation(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Location" onChange={(e) => setLocation(e.target.value)} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="department"
                                    size="small"
                                    fullWidth
                                    freeSolo
                                    options={departments || []}
                                    value={department}
                                    getOptionLabel={(option) => typeof option === "string" ? option : option.name}
                                    onChange={(event, newValue) => setDepartment(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Department" onChange={(e) => setDepartment(e.target.value)} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="companyName"
                                    size="small"
                                    fullWidth
                                    freeSolo
                                    options={companyNames || []}
                                    value={companyName}
                                    getOptionLabel={(option) => typeof option === "string" ? option : option.name}
                                    onChange={(event, newValue) => setCompanyName(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Company Name" onChange={(e) => setCompanyName(e.target.value)} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="country"
                                    size="small"
                                    fullWidth
                                    freeSolo
                                    options={countries || []}
                                    value={country}
                                    getOptionLabel={(option) => typeof option === "string" ? option : option.name}
                                    onChange={(event, newValue) => setCountry(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Country" onChange={(e) => setCountry(e.target.value)} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="state"
                                    size="small"
                                    fullWidth
                                    freeSolo
                                    options={states || []}
                                    value={state}
                                    getOptionLabel={(option) => typeof option === "string" ? option : option.name}
                                    onChange={(event, newValue) => setState(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="State" onChange={(e) => setState(e.target.value)} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="city"
                                    size="small"
                                    fullWidth
                                    freeSolo
                                    options={cities || []}
                                    value={city}
                                    getOptionLabel={(option) => typeof option === "string" ? option : option.name}
                                    onChange={(event, newValue) => setCity(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="City" onChange={(e) => setCity(e.target.value)} />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={3} display="flex" justifyContent="flex-end" spacing={2}>
                            <Button onClick={onClose} variant="outlined" color="inherit" sx={{ mr: 2 }}>Cancel</Button>
                            <Button onClick={handleSave} variant="contained" color="warning" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : "Add User"}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};
