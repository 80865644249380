import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, TextField, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const FeedbackButton = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [open, setOpen] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const theme = useTheme();


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        setIsAdmin((user && user.role === "Administrators") || user && user?.role?.includes("TenancyAdministrators") );
        if (user && user.email) {
            setUserEmail(user.email);
        }
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    if (!isAdmin) return null;

    return (
        <>
            <Button variant="contained" color="warning" onClick={handleOpen}>
                Feedback
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="feedback-modal-title"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: theme.palette.background.default,
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="feedback-modal-title" variant="h6" component="h2" gutterBottom>
                        Provide Feedback
                    </Typography>
                    <form action="https://submit-form.com/gwc9ZdAge" method="POST">
                        <input
                            type="hidden"
                            name="_redirect"
                            value="https://beta.boxphish.com/settings"
                        />
                        <label htmlFor="name">Name</label>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="name"
                            name="name"
                            placeholder="Name"
                            required
                        />
                        <label htmlFor="email">Email</label>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={userEmail}
                            InputProps={{
                                readOnly: true,
                            }}
                            required
                        />
                        <label htmlFor="message">Message</label>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="message"
                            name="message"
                            multiline
                            rows={4}
                            placeholder="Message"
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                        >
                            Send
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default FeedbackButton;
