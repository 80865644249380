import React, { useEffect, useCallback } from "react";
import { useSearchParams } from 'react-router-dom';
import { APP_URL } from "../../config";
import {
    useGetCampaignResponseQuery,
    useGetLandingPageQuery,
    useLazyPostCampaignLandingResponseQuery  // Changed to lazy query
} from '../../store/services/simulation-service';

const TwoStageLandingPage = () => {
    const [searchParams] = useSearchParams();
    const identifier = searchParams.get('identifier');

    const [triggerPostLandingResponse] = useLazyPostCampaignLandingResponseQuery();

    const {
        data: campaignData,
        isLoading: isCampaignLoading,
        error: campaignError,
        refetch: refetchCampaign
    } = useGetCampaignResponseQuery(identifier, {
        skip: !identifier
    });

    const {
        data: landingPageData,
        isLoading: isLandingPageLoading,
        error: landingPageError
    } = useGetLandingPageQuery(campaignData?.campaignId, {
        skip: !campaignData?.campaignId
    });

    const handleMessage = useCallback(async (event) => {
        console.log('Message received:', event.data);

        if (event.data === "ACTION_LINK_CLICKED") {
            try {
                const result = await refetchCampaign();
                console.log("Campaign response data:", result.data);

                // Trigger the lazy query
                await triggerPostLandingResponse(identifier);

                setTimeout(() => {
                    window.location.href = `/campaignclicked?identifier=${identifier}`;
                }, 3000);
            } catch (error) {
                console.error("Error processing campaign response:", error);
            }
        }
    }, [identifier, refetchCampaign, triggerPostLandingResponse]);

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [handleMessage]);

    if (campaignError || landingPageError) {
        return <div>Error loading page</div>;
    }

    if (!landingPageData) {
        return null;
    }

    return (
        <iframe
            src={`${APP_URL}/${landingPageData.landingPagePath}`}
            style={{
                width: "100%",
                height: "100vh",
                border: "none",
                overflow: "hidden",
                zIndex: 9999
            }}
            title={`course-${landingPageData?.simulationName}`}
        />
    );
};

export default TwoStageLandingPage;