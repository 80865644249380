import React, { useState } from "react";
import { useGenerateNewAccessKeyMutation } from "../../store/services/tenancy.service";
import { useTheme } from "@mui/material/styles";
import { API_DOC_URL, APP_URL} from "config";
import { Alert, Box, Button, Grid, IconButton, OutlinedInput, Snackbar, Typography } from "@mui/material";
import EyeOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MainCard from "../../components/cards/MainCard";
import Divider from "@mui/material/Divider";
import { getCardColor } from "../../utils/colorUtils";

export default function ApiIntegration(props) {
    const { data } = props;
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const [tenancyData, setTenancyData] = useState(data);

    const [viewApiKey, setViewApiKey] = useState(false);
    const [generateNewAccessKey] = useGenerateNewAccessKeyMutation();

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const handleGenerateNewAccessKey = async () => {
        try {
            const response = await generateNewAccessKey({ tenancyId: tenancyData.tenancyId }).unwrap();
            setTenancyData(prev => ({
                ...prev,
                reportAccessKey: response.ReportAccessKey // I don't know why generateNewAccessKey is returning the values in PascalCase. I'm just following the pattern here.
            }));

            setSnackbarMessage("New access key generated successfully." + response.ReportAccessKey);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage("Error generating new access key.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.log("Error generating new access key:", error);
        }
    };

    const handleGoToApiDocs = () => {

        window.open(API_DOC_URL);

    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <MainCard content={true} boxShadow={true} border={false}
                        sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                        <Box sx={{ width: "100%", mb: 1 }}>
                            <Typography variant="h4">
                                API Integration
                            </Typography>
                        </Box>
                        <Grid item xs={12}>
                            <Divider sx={{ width: "100%" }} />
                        </Grid>
                        <Typography variant="caption">
                            Authentication Token
                        </Typography>
                        <Box>
                            <OutlinedInput
                                readOnly={true}
                                type={viewApiKey ? "text" : "password"}
                                value={tenancyData.reportAccessKey}
                                id={"reportAccessKey"}
                                sx={{ width: "92%", mr: 2, bgcolor: 'background.default' }}
                            />

                            <IconButton
                                size="small"
                                color={viewApiKey ? "primary" : "default"}
                                onClick={() => setViewApiKey(!viewApiKey)}
                            >
                                <EyeOutlinedIcon />
                            </IconButton>

                            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                <Button
                                    onClick={() => handleGenerateNewAccessKey()}
                                    variant="contained"
                                    color="warning"
                                    sx={{width:"45%"}}
                                >
                                    Generate New Report Token
                                </Button>
                                <Button
                                    onClick={handleGoToApiDocs}
                                    variant="contained"
                                    color="primary"
                                    sx={{width:"45%"}}
                                >
                                    Go to API Documentation
                                </Button>
                            </Box>
                        </Box>
                    </MainCard>
                </Grid>
            </Grid>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                      onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}
