import { useSelector } from "react-redux";
import { Tab, Tabs, useMediaQuery, Grid, Button, Typography, Box, Divider, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import { APP_URL } from "../../config";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import Highlights from "./Highlights"
import RSSFeed from "./RSSFeed"
import { useDynamicBreakpoints } from '../../utils/breakpointUtils';
import { getCardColor } from '../../utils/colorUtils';


const Feed = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
    const { drawerOpen } = useSelector((state) => state.menu);
    const { matchDownMDS } = useDynamicBreakpoints(drawerOpen);
    const [feedFilter, setFeedFilter] = useState("All");
    const lightMode = theme.palette.mode === "light";

    const { user } = useSelector((state) => state.auth);

    const userLoggedIn = JSON.parse(localStorage.getItem("user"));
    const isUser = userLoggedIn && userLoggedIn?.role === "";

    const exampleData = {
        '18/09/24': [
            { type: 'new', text: 'Added dark mode support' },
            { type: 'new', text: 'Added send to jedi master button' },
            { type: 'new', text: 'Implemented return to sender feature' },
            { type: 'improved', text: 'Enhanced search functionality' },
            { type: 'fixed', text: 'Resolved issue with user login' },
            { type: 'fixed', text: 'Resolved issue with admin user logout' }
        ],
        '17/09/24': [
            { type: 'new', text: 'Implemented file sharing feature' },
            { type: 'improved', text: 'Enhanced connection to the dark side of the force' },
            { type: 'improved', text: 'Improved load times' },
            { type: 'improved', text: 'Downloaded more RAM' },
            { type: 'fixed', text: 'Fixed bug in profile update' }
        ],
        '16/09/24': [
            { type: 'new', text: 'Added delete all users button' },
            { type: 'new', text: 'Added export to middle-earth funtionality' },
            { type: 'improved', text: 'Improved bugfixing through a load of print statements' },
            { type: 'fixed', text: 'Got rid of bugs in my keyboard' }
        ]

    };

    useEffect(() => {
        if (!user || !user.email) {
            window.location.href = "/login";
        }
    }, [user]);

    return (
        <Grid container spacing={2}>
           <Grid item xs={12} sx={{ height: '100%' }}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                    <Grid item xs={12}>
                        <MainCard
                            content={false}
                            sx={{
                                width: "100%",
                                bgcolor: "background.default",
                                p: 4,
                                height: '100%'
                            }}
                            border={false}
                            boxShadow={true}
                        >
                            <Box>
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item>
                                        <Typography variant="h2">News Feed</Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel style={{ backgroundColor: "transparent" }}>Filter By</InputLabel>
                                            <Select
                                                variant="outlined"
                                                size="small"
                                                sx={{ mr: 4, minWidth: 120 }}
                                                value={feedFilter}
                                                onChange={(e) => setFeedFilter(e.target.value)}
                                                label="Status"
                                            >
                                                <MenuItem value="All">Recent</MenuItem>
                                                <MenuItem value="Infosecurity Magazine">Infosecurity Magazine</MenuItem>
                                                {/*<MenuItem value="Bleeping Computer">Bleeping Computer</MenuItem>*/}
                                                <MenuItem value="Hackread">Hackread</MenuItem>
                                                <MenuItem value="Security Affairs">Security Affairs</MenuItem>
                                                <MenuItem value="National Cyber Security Centre">NCSC</MenuItem>
                                                <MenuItem value="Welivesecurity">Welivesecurity</MenuItem>
                                                <MenuItem value="GBHackers">GBHackers</MenuItem>
                                                <MenuItem value="McAfee">McAfee</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider sx={{ width: "100%", mb: 4, mt: 2 }} />
                            <RSSFeed selectedFilter={feedFilter} />
                        </MainCard>
                    </Grid>
                    {/*
                        <Grid item xs={4.5}>
                            <MainCard
                                content={false}
                                sx={{
                                    width: "100%",
                                    bgcolor: "background.default",
                                    p: 4,
                                    height: '100%'
                                }}
                                border={false}
                                boxShadow={true}
                            >
                                <Highlights data={exampleData} />
                            </MainCard>
                        
                            <MainCard
                                content
                                boxShadow
                                border={false}
                                sx={{
                                    bgcolor: theme => theme.palette.mode === 'light' ? '#F8F8F8' : getCardColor(lightMode),
                                    display: "flex",
                                    flexDirection: "column",
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="body1">Highlights (coming soon...)</Typography>
                                </Box>
                            </MainCard>
                        </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
 }

export default Feed;