import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useOutlookPluginHandlerQuery } from "../../store/services/tenancy.service";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { APP_URL } from "config";

const OutlookPluginHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Parse URL parameters
    const params = new URLSearchParams(location.search);
    const adminConsent = params.get('admin_consent');
    const tenant = params.get('tenant');
    const state = params.get('state');

    const { data, error, isLoading } = useOutlookPluginHandlerQuery({
        tenancyId: state,
        tenancy: tenant
    }, {
        skip: !adminConsent || !tenant || !state || adminConsent !== 'True'
    });

    useEffect(() => {
        let redirectTimer;

        if (error || data) {
            if (error) {
                setSnackbarMessage("Failed to process Microsoft permissions");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            } else if (data) {
                setSnackbarMessage("Successfully granted Microsoft permissions");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            }

            // Set a timer to redirect after showing the snackbar
            redirectTimer = setTimeout(() => {
                window.location.href = `${APP_URL}/settings`;
            }, 2000); // Wait 2 seconds before redirecting
        }

        // Cleanup timer on unmount
        return () => {
            if (redirectTimer) {
                clearTimeout(redirectTimer);
            }
        };
    }, [data, error]);

    return (
        <>
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    {isLoading ? (
                        <p>Processing Microsoft permissions...</p>
                    ) : error ? (
                        <p>Error processing permissions. Redirecting to settings...</p>
                    ) : data ? (
                        <p>Permissions granted successfully. Redirecting to settings...</p>
                    ) : (
                        <p>Initializing...</p>
                    )}
                </div>
            </div>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default OutlookPluginHandler;