import {
    Box, Button, Chip,
    Grid, Menu,
    MenuItem,
    Modal,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import GroupTable from "../on-demand/Tables/GroupTable";
import ImportGroupTable from "./ImportGroupTable";
import { useSelector } from "react-redux";
import {
    useGetAuditLogsForTenancyQuery,
    useGetImportStatusQuery,
    useRefreshSyncGroupsMutation
} from "../../store/services/tenancy.service";
import React, { useCallback, useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ColorLibConnector from "../../components/ColorLibConnector";
import CustomStepIcon from "../../components/Icon/CustomStepIcon";
import AdminLogsTable from "./AdminLogsTable";
import MainCard from "../../components/cards/MainCard";
import SyncStatusCard from "../../components/cards/SyncStatusCard";
import { useTheme } from "@mui/material/styles";
import { APP_URL, GOOGLE_CLIENT_CODE_IMPORT, OFFICE_CLIENT_CODE_SYNC } from "../../config";
import AddIcon from "@mui/icons-material/Add";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { formatDateTime } from "../../utils/formatDateTime";
import { useNavigate } from 'react-router-dom';
import { getCardColor, getTextColor } from '../../utils/colorUtils';



const STEPS = ["Start", "In Progress", "Success"];

const UserSync = ({ openModal, setOpenModal }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { tenancyId } = useSelector((state) => state.tenancy);
    const { data: auditLogsData, isLoading: auditLogsLoading, error: auditLogsError, refetch: refetchAuditLogs } = useGetAuditLogsForTenancyQuery({ tenancyId });
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const navigate = useNavigate();
    const [latestImportStatus, setLatestImportStatus] = useState(null);

    const {
        data: importStatusData = [],
        error: importStatusError,
        isLoading: importStatusLoading,
        refetch
    } = useGetImportStatusQuery({ tenancyId });

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        boxShadow: 24,
        maxHeight: "80vh",
        bgcolor:getCardColor(lightMode),
        border: "0px solid",
        outline: 'none',
    };

    const [ refreshSyncGroups ] = useRefreshSyncGroupsMutation();

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    const [importMenuAnchorEl, setImportMenuAnchorEl] = useState(null);
    const [importMenuOpen, setImportMenuOpen] = useState(false);

    const importMenuId = importMenuOpen ? "import-menu" : undefined;

    const handleImportMenuClick = (event) => {
        setImportMenuAnchorEl(event.currentTarget);
        setImportMenuOpen(true);
    };

    const handleImportMenuClose = () => {
        setImportMenuAnchorEl(null);
        setImportMenuOpen(false);
    };

    const handleRefresh = useCallback(() => {
        refetchAuditLogs();
    }, [refetchAuditLogs]);


    const updateStepper = (status) => {
        switch (status) {
            case "Start":
                setActiveStep(0);
                setCompleted({});
                break;
            case "In Progress":
                setActiveStep(1);
                setCompleted({});
                break;
            case "Success":
                setActiveStep(3);
                break;
            case "Error":
                setActiveStep(2);
                break;
            default:
                break;
        }
    };
    const getLatestImportStatus = (data) => {
        if (!data || data.length === 0) {
            return null;
        }
        const sortedData = [...data].sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
        return sortedData[0];
    };

    useEffect(() => {
        if (importStatusData) {
            setLatestImportStatus(getLatestImportStatus(importStatusData));
        }
    }, [importStatusData]);

    useEffect(() => {
        if (latestImportStatus && !importStatusLoading && !importStatusError) {
            const status = latestImportStatus?.status || "Start";
            updateStepper(status);


            if (status === "Success") {
                navigate('/users?tab=1');
            }
        }

        const pollImportStatus = () => {
            const status = latestImportStatus?.status || "Start";

            if (status !== "Success" && status !== "Error") {
                refetch();
            }
        };

        const intervalId = setInterval(pollImportStatus, 5000);

        return () => clearInterval(intervalId);
    }, [latestImportStatus, refetch, navigate]);


    const handleConnect = (origin) => {
        if (origin === "office") {
            window.open(
                `https://login.microsoftonline.com/common/adminconsent?client_id=${OFFICE_CLIENT_CODE_SYNC}&redirect_uri=${APP_URL}/officesync&state=${tenancyId}`,
                "_blank"
            );
        } else if (origin === "google") {
            window.open(`https://accounts.google.com/o/oauth2/auth?access_type=offline&prompt=consent&scope=https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.orgunit.readonly&response_type=code&client_id=${GOOGLE_CLIENT_CODE_IMPORT}&redirect_uri=${APP_URL}/googlehandler`,
                '_blank');
        }
    }

    const refreshGroups = async (origin) => {
        setCompleted({});
        setActiveStep(0);

        const data = {
            tenancyId: tenancyId,
            RequestorEmail: user.email,
            Origin: { type: origin === "google" ? "google" : "office" },
            OfficeAuthCode: "",
        };

        refreshSyncGroups({ tenancyId: tenancyId, data: data })
            .unwrap()
            .then(() => {
                refetch();
            })
            .catch((error) => {
                console.error("Error refreshing groups: ", error);
            });
    };

    const getSyncStatus = () => {
        if (importStatusError) return 'Error';
        if (importStatusLoading) return 'Loading';
        if (latestImportStatus?.sk === "google") return 'Google';
        if (latestImportStatus?.sk === "office") return 'Microsoft';
        return 'Other';
    }

    const isNoSync = () => {
        return !importStatusLoading && !importStatusError && importStatusData && importStatusData.length === 0;
    }

    return (
        <>
            <Box sx={{
                minWidth: '550px',  
            }}>
                <Grid
                    container
                    spacing={2.75}
                    alignItems="center"
                    sx={{
                        flexWrap: 'nowrap' 
                    }}
                >
                    {!isNoSync() && (
                        <Grid item style={{
                            width: '200px',
                            flexShrink: 0  
                        }}>
                            <SyncStatusCard status={getSyncStatus()} />
                        </Grid>
                    )}

                    <Grid item style={{
                        flex: '1 1 auto',
                        minWidth: '300px  !important'
                    }}>
                        <MainCard
                            content
                            boxShadow
                            border={false}
                            sx={{
                                bgcolor: getCardColor(lightMode),
                                display: "flex",
                                flexDirection: "column",
                                height: isNoSync() ? 75 : 50,
                                justifyContent: "center",
                                minWidth: '300px'
                            }}
                        >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        {importStatusLoading && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                <Typography variant="h6">Loading Import Status...</Typography>
                            </Box>
                        )}

                        {importStatusError && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                <Typography variant="h6">Error fetching import status.</Typography>
                            </Box>
                        )}

                        {!importStatusLoading && !importStatusError && (
                                <>
                                    {importStatusData && importStatusData.length === 0 ? (
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                                Connect to Microsoft 365 or Google to sync your groups
                                            </Typography>

                                            <Button
                                                size="medium"
                                                variant="contained"
                                                sx={{ textTransform: "capitalize", mr: 1 }}
                                                aria-controls={importMenuId}
                                                aria-haspopup="true"
                                                onClick={handleImportMenuClick}
                                                endIcon={<AddIcon />}
                                            >
                                                Connect
                                            </Button>

                                            <Menu
                                                id={importMenuId}
                                                anchorEl={importMenuAnchorEl}
                                                open={importMenuOpen}
                                                onClose={handleImportMenuClose}
                                                MenuListProps={{
                                                    "aria-labelledby": "add-user-button",
                                                    sx: { bgcolor: "background.default" },
                                                }}
                                            >
                                                <MenuItem onClick={() => handleConnect("google")}>
                                                    <GoogleIcon sx={{ mr: 1 }} />
                                                    Connect to Google
                                                </MenuItem>
                                                <MenuItem onClick={() => handleConnect("office")}>
                                                    <MicrosoftIcon sx={{ mr: 1 }} />
                                                    Connect to Microsoft 365
                                                </MenuItem>
                                            </Menu>
                                        </Box>) :

                                (<Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <Typography variant="subtitle1">Configured:</Typography>
                                                <Typography variant="caption" color={getTextColor(getCardColor(lightMode))} sx={{ ml: 1}}>
                                            {formatDateTime(latestImportStatus?.dateTime) || "Unknown"}
                                        </Typography>
                                    </Box>
                                </Box>)}

                        </>

                        )}
                    </Box>
                </MainCard>
                </Grid>
                    {!isNoSync() && (
                        <Grid item style={{
                            width: '250px',  
                            flexShrink: 0    
                        }}>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",  
                                justifyContent: "center",
                                gap: 2.75
                            }}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ textTransform: "capitalize",  }}
                                    onClick={() => { window.location.href = '/users?tab=3'; }}
                                    color='secondary'
                                >
                                    Logs
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ textTransform: "capitalize" }}
                                    aria-controls={importMenuId}
                                    aria-haspopup="true"
                                    onClick={handleImportMenuClick}
                                >
                                    Reconfigure
                                </Button>

                                <Menu
                                    anchorEl={importMenuAnchorEl}
                                    open={Boolean(importMenuAnchorEl)}
                                    onClose={handleImportMenuClose}
                                >
                                    <MenuItem onClick={() => handleConnect("google")}>
                                        <GoogleIcon sx={{ mr: 1 }} />
                                        Connect to Google
                                    </MenuItem>
                                    <MenuItem onClick={() => handleConnect("office")}>
                                        <MicrosoftIcon sx={{ mr: 1 }} />
                                        Connect to Microsoft 365
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Grid container rowSpacing={4.5} columnSpacing={2.75} alignItems="stretch" sx={{mt: 1}}>
                <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
                    <Box sx={{ width: '100%' }}>
                        {isNoSync() ?
                            <MainCard
                                content
                                boxShadow
                                border={false}
                                sx={{
                                    bgcolor: "#F8F8F8",
                                    display: "flex",
                                    flexDirection: "column",
                                    width: '100%',
                                    height: '500px',
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="body1">Connect to Google or Microsoft to see user groups</Typography>
                                </Box>
                            </MainCard> :
                            <GroupTable type={"edit"} />
                         }
                    </Box>
                </Grid>

            {openModal && (
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={12}>
                                {activeStep !== 3 && (
                                    <Stack direction="column" spacing={4} alignItems="center">
                                        <CheckCircleOutlineIcon color="primary" style={{ fontSize: 50 }} />
                                        <Typography variant="h4" align="center">
                                            The import of your {latestImportStatus?.sk === "office" ? "Microsoft 365" : "Google"}
                                            &nbsp;groups {latestImportStatus && latestImportStatus.status === "Success" ? "has completed" : "has started"}.
                                        </Typography>
                                        <Box width={"100%"}>
                                            <Stepper activeStep={activeStep} alternativeLabel
                                                     connector={<ColorLibConnector />}>
                                                {STEPS.map((label, index) => (
                                                    <Step key={label} completed={completed[index]}>
                                                        <StepLabel
                                                            StepIconComponent={(props) => <CustomStepIcon {...props} />}>
                                                            {label}
                                                        </StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </Box>

                                        {importStatusError && (
                                            <Typography color="error" align="center">
                                                There was an error with the import process. Please try again.
                                            </Typography>
                                        )}
                                    </Stack>
                                )}
                                {activeStep === 3 && (
                                    <Box p={2} width={"100%"}>
                                        <ImportGroupTable closeModal={() => setOpenModal(false)} />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            )}
            </Grid>
        </>
    );
};

export default UserSync;
