import React, { useCallback, useState, useEffect } from "react";
import { Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { useNavigate } from "react-router-dom";
import { getCardColor } from "../../utils/colorUtils";
import { useTheme } from "@mui/material/styles";
import { useThemeContext } from "../../themes/ThemeContext";
import { useLazyGetTinyJwtTokenQuery } from "../../store/services/simulation-service";
import { useSaveUniversityCourseSettingsMutation } from "../../store/services/university-service";
import { Editor } from "@tinymce/tinymce-react";
import ColorPicker from "../../components/CustomTemplateColorPicker";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

CustomEmailTemplate.propTypes = {
    data: PropTypes.object
};

export default function CustomEmailTemplate (props) {
    const { data = {} } = props; // Provide empty object as default value
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const storedColors = localStorage.getItem('themeColors');
    const primaryColor = storedColors ? JSON.parse(storedColors).primary : theme.palette.primary.main;
    const secondaryColor = storedColors ? JSON.parse(storedColors).secondary : theme.palette.secondary.main;
    const tertiaryColor = storedColors ? JSON.parse(storedColors).warning : theme.palette.warning.main;

    const [tenancyData, setTenancyData] = useState(data);

    const { tenancyId } = useSelector((state) => state.tenancy);

    // Initialize formData with default values and safe access to tenancyId
    const [formData, setFormData] = useState({
        tenancyId: tenancyId,
        displayName: "",
        courseEmailSubject: "",
        reminderEmailSubject: "",
        courseBodyText: "",
        reminderBodyText: "",
        courseButtonText: "",
        reminderButtonText: "",
        courseButtonStyle: "",
        reminderButtonStyle: "",
    });

    const [saveSettings] = useSaveUniversityCourseSettingsMutation();

    // Update formData when data prop changes
    useEffect(() => {
        if (data?.tenancyId) {
            setTenancyData(data);
            setFormData(prevFormData => ({
                ...prevFormData,
                tenancyId: data.tenancyId
            }));
        }
    }, [data]);

    const handleInputChange = (field) => (event) => {
        setFormData({
            ...formData,
            [field]: event.target.value
        });
    };

    const handleEditorChange = (field) => (content) => {
        setFormData({
            ...formData,
            [field]: content
        });
    };

    const handleSave = async () => {
        if (!formData.tenancyId) {
            console.error('No tenancyId available');
            return;
        }

        try {
            await saveSettings({
                tenancyId: formData.tenancyId,
                settingsData: {
                    ...formData
                }
            }).unwrap();
            navigate(-1);
        } catch (error) {
            console.error('Failed to save settings:', error);
        }
    };


    const buttonStyles = ["Long", "Short", "Rounded", "Square"];


    const [triggerJwtToken, { data: jwtToken, isLoading: jwtTokenLoading, error: jwtTokenError }] = useLazyGetTinyJwtTokenQuery();

    const editorStyle = `
        body { 
            background-color: ${lightMode ? 'white' : theme.palette.black.main}; 
            color: ${lightMode ? 'black' : theme.palette.grey[50]}; 
        }
    `;

    const tinydriveTokenProvider = useCallback((callback) => {
        const getToken = async () => {
            try {
                const response = await triggerJwtToken().unwrap();
                if (response) {
                    callback(response);
                } else {
                    console.warn("JWT token not available in the response");
                    callback(null);
                }
            } catch (error) {
                console.error("Error fetching JWT token:", error);
                callback(null);
            }
        };

        getToken();
    }, [triggerJwtToken]);

    const saveCustomTemplateSettings = useSaveUniversityCourseSettingsMutation();

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(location.from ? location.from : -1)}
            >
                Back
            </Button>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
                mt: 3
            }}>
                <Typography variant="h2">Custom Email Template</Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="outlined"
                        color="black"
                        onClick={() => navigate(location.from ? location.from : -1)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={handleSave}

                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="info"


                    >
                        Send Example
                    </Button>

                </Box>
            </Box>
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} md={6} lg={5}>
                    <MainCard
                        content={true}
                        boxShadow={true}
                        border={false}
                        sx={{
                            height: '100%',
                            bgcolor: getCardColor(lightMode),
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <TextField
                            label="Template Name"
                            required={false}
                            size="medium"
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Display Name"
                            value={formData.displayName}
                            onChange={handleInputChange('displayName')}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Email Subject"
                            value={formData.courseEmailSubject}
                            onChange={handleInputChange('courseEmailSubject')}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <Editor
                            apiKey="j2g2hy77ifpdh2oe7hfarnk6vvls82qlv49guynyzw6d22ha"
                            init={{
                                skin: lightMode ? 'oxide' : 'oxide-dark',
                                content_css: lightMode ? 'default' : 'dark',
                                content_style: editorStyle,
                                plugins: [
                                    "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                                    "searchreplace", "visualblocks", "code", "fullscreen",
                                    "insertdatetime", "media", "table", "code", "help", "wordcount", "template", "tinydrive"
                                ],
                                images_file_types: "jpg,png,jpeg,webp",
                                tinydrive_token_provider: tinydriveTokenProvider,
                                toolbar:
                                    "template image | code | undo redo | fontselect fontsize fontsizeselect bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                                templates: [
                                    {
                                        title: "User name",
                                        description: "Insert the users full name",
                                        content: "{user.name}"
                                    }
                                ],
                                height: 500,
                                promotion: false,
                                file_picker_types: 'media image'
                            }}
                        />
                        <Autocomplete
                            value={formData.courseButtonStyle}
                            onChange={(_, newValue) => {
                                setFormData({
                                    ...formData,
                                    courseButtonStyle: newValue
                                });
                            }}
                            options={buttonStyles}
                            renderInput={(params) => <TextField {...params} label="Button Style" />}
                            sx={{ mt: 2, mb: 2 }}
                        />
                        <TextField
                            label="Button Text"
                            required={false}
                            size="medium"
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                    </MainCard>
                </Grid>
                <Grid item xs={12} md={6} lg={7}>
                    <MainCard
                        content={true}
                        boxShadow={true}
                        border={false}
                        sx={{
                            height: '100%',
                            bgcolor: getCardColor(lightMode),
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                    </MainCard>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} lg={5}>
                    <MainCard
                        content={true}
                        boxShadow={true}
                        border={false}
                        sx={{
                            height: '100%',
                            bgcolor: getCardColor(lightMode),
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 14
                        }}>
                            <ColorPicker
                                label="Primary"
                                color={primaryColor}
                                onChange={(color) => {}}
                            />
                            <ColorPicker
                                label="Secondary"
                                color={secondaryColor}
                                onChange={(color) => {}}
                            />
                            <ColorPicker
                                label="Tertiary"
                                color={tertiaryColor}
                                onChange={(color) => {}}
                            />
                        </Box>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};
