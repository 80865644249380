import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Autocomplete, Box, Grid, Snackbar, Switch, TextField, Typography } from "@mui/material";
import { useGetUserByIdQuery, useUpdateUserMutation, useUpdateUserRoleMutation } from "../../store/services/user-service";
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import LoadingSwitch from '../../components/LoadingSwitch';

UserSettings.propTypes = {
    userData: PropTypes.object.isRequired,
    tenancyData: PropTypes.object.isRequired,
};

export default function UserSettings({ userData, tenancyData }) {
    const theme = useTheme();
    const [updateUser] = useUpdateUserMutation();
    const [updateUserRole] = useUpdateUserRoleMutation();
    const lightMode = theme.palette.mode === "light";

    const { data: user, error, isLoading, refetch } = useGetUserByIdQuery({ userId: userData.id });

    const [userDetails, setUserDetails] = useState(null);
    const [reportingEnabled, setReportingEnabled] = useState(false);
    const [onDemandEnabled, setOnDemandEnabled] = useState(false);
    const [loadingToggles, setLoadingToggles] = useState({
        enabled: false,
        admin: false,
        reportLevel: false,
        onDemandLevel: false
    });

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const tenancyReportingEnabled = tenancyData?.enableReporting || false;
    const tenancyOnDemandEnabled = tenancyData?.enableOndemand || false;

    useEffect(() => {
        if (user) {
            setUserDetails(user);
            setReportingEnabled(user.reportingLevel !== null);
            setOnDemandEnabled(user.onDemandLevel !== null);
        }
    }, [user]);

    const setToggleLoading = (toggleName, isLoading) => {
        setLoadingToggles(prev => ({ ...prev, [toggleName]: isLoading }));
    };

    const handleSnackbar = (message, severity = "success") => {
        setSnackbarState({ open: true, message, severity });
    };

    const handleEnableChange = async () => {
        setToggleLoading('enabled', true);
        const newEnabled = !userDetails.enabled; 
        try {
            await updateUser({ userId: userDetails.id, user: { ...userDetails, enabled: newEnabled } }).unwrap();
            setUserDetails((prevState) => ({ ...prevState, enabled: newEnabled }));
            handleSnackbar(`User has been ${newEnabled ? "enabled" : "disabled"}`);
        } catch {
            handleSnackbar(`Error occurred while ${newEnabled ? "enabling" : "disabling"} user`, "error");
        } finally {
            setToggleLoading('enabled', false);
        }
    };

    const handleAdminChange = async () => {
        setToggleLoading('admin', true);
        const newRole = !userDetails?.role?.includes("TenancyAdministrators") ? "TenancyAdministrators" : "User";
        try {
            await updateUserRole({ userId: userDetails.id, role: newRole }).unwrap();
            setUserDetails((prevState) => ({ ...prevState, role: newRole }));
            handleSnackbar(`User role has been changed to ${newRole === "TenancyAdministrators" ? "Tenancy Administrators" : "User"}`);
        } catch {
            handleSnackbar(`Error occurred while changing user role to ${newRole === "TenancyAdministrators" ? "Tenancy Administrators" : "User"}`, "error");
        } finally {
            setToggleLoading('admin', false);
        }
    };

    const handleLevelChange = async (event, newValue, type) => {
        const updatedUser = { ...userDetails, [type]: newValue };
        try {
            await updateUser({ userId: userDetails.id, user: updatedUser }).unwrap();
            setUserDetails(updatedUser);
            handleSnackbar(`${type === "reportingLevel" ? "Reporting" : "On-Demand"} level updated successfully`);
        } catch {
            handleSnackbar(`Error updating ${type === "reportingLevel" ? "reporting" : "on-demand"} level`, "error");
        }
    };

    const switchLevelEnableChange = async (type) => {
        type === "reportingLevel" ? setToggleLoading('reportLevel', true) : setToggleLoading('onDemandLevel', true);
        const newEnabled = type === "reportingLevel" ? !reportingEnabled : !onDemandEnabled;
        const levelKey = type === "reportingLevel" ? "reportingLevel" : "onDemandLevel";
        const updatedUser = {
            ...userDetails,
            [levelKey]: newEnabled ? (userDetails[levelKey] || "Tenancy") : null
        };

        try {
            await updateUser({ userId: userDetails.id, user: updatedUser }).unwrap();
            setUserDetails(updatedUser);
            if (type === "reportingLevel") {
                setReportingEnabled(newEnabled);
            } else {
                setOnDemandEnabled(newEnabled);
            }
            handleSnackbar(`${type === "reportingLevel" ? "Reporting" : "On-Demand"} level has been ${newEnabled ? "enabled" : "disabled"}`);
        } catch {
            handleSnackbar(`Error ${newEnabled ? "enabling" : "disabling"} ${type === "reportingLevel" ? "reporting" : "on-demand"} level`, "error");
        } finally {
            type === "reportingLevel" ? setToggleLoading('reportLevel', false) : setToggleLoading('onDemandLevel', false);
        }
    };

    const isAnySwitchLoading = (loadingToggles) => {
        return Object.values(loadingToggles).some(state => state === true);
    };

    return (
        <>
            {userDetails && !isLoading &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <MainCard
                                    content={true}
                                    boxShadow={true}
                                    border={false}
                                    sx={{ height: "100%", bgcolor: lightMode ? "primary.white" : "grey.600" }}
                                >
                                    <Box
                                        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                    >
                                        <Typography variant={"h4"}>{userDetails?.enabled ? "Enabled" : "Disabled"} </Typography>

                                        <LoadingSwitch
                                            checked={userDetails.enabled}
                                            onChange={handleEnableChange}  
                                            loading={loadingToggles.enabled}
                                            disabled={isAnySwitchLoading(loadingToggles)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    </Box>
                                    <Typography variant={"caption"}>Toggle to enable or disable the user.</Typography>
                                </MainCard>
                            </Grid>

                            {userDetails?.role !== "Administrators" && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{ height: "100%", bgcolor: lightMode ? "primary.white" : "grey.600" }}
                                    >
                                        <Box
                                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                        >
                                            <Typography variant={"h4"}>Tenancy Admin</Typography>
                                            <LoadingSwitch
                                                checked={userDetails?.role?.includes("TenancyAdministrators")}
                                                onChange={handleAdminChange} 
                                                loading={loadingToggles.admin}
                                                disabled={isAnySwitchLoading(loadingToggles)}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </Box>
                                        <Typography variant={"caption"}>
                                            Toggle to assign or remove tenancy admin role.
                                        </Typography>
                                    </MainCard>
                                </Grid>
                            )}

                            {tenancyReportingEnabled && userDetails?.role !== "Administrators" &&  !userDetails?.role?.includes("TenancyAdministrators")  && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{ height: "100%", bgcolor: lightMode ? "primary.white" : "grey.600" }}
                                    >
                                        <Box
                                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                        >
                                            <Typography variant={"h4"}>Reporting Level</Typography>
                                            <LoadingSwitch
                                                checked={reportingEnabled}
                                                onChange={() => switchLevelEnableChange("reportingLevel")}
                                                loading={loadingToggles.reportLevel}
                                                disabled={isAnySwitchLoading(loadingToggles)}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </Box>
                                        <Typography variant={"caption"}>
                                            Toggle reporting access. When enabled, select an access level from the dropdown. Changes may take up to 2 hours to become effective.
                                        </Typography>
                                        <Box
                                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", mt: 4 }}
                                        >
                                            {reportingEnabled && (
                                                <Autocomplete
                                                    options={[
                                                        "Tenancy",
                                                        "Department",
                                                        "Location",
                                                        "City",
                                                        "Country",
                                                        "Company Name",
                                                        "State",
                                                    ]}
                                                    getOptionLabel={(option) => option}
                                                    value={userDetails?.reportingLevel}
                                                    fullWidth={true}
                                                    onChange={(event, newValue) => handleLevelChange(event, newValue, "reportingLevel")}
                                                    renderInput={(params) => <TextField {...params} label="" />}
                                                />
                                            )}
                                        </Box>
                                    </MainCard>
                                </Grid>
                            )}

                            {tenancyOnDemandEnabled && userDetails?.role !== "Administrators" && !userDetails?.role?.includes("TenancyAdministrators") && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{ height: "100%", bgcolor: lightMode ? "primary.white" : "grey.600" }}
                                    >
                                        <Box
                                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                        >
                                            <Typography variant={"h4"}>On-Demand Level</Typography>
                                            <LoadingSwitch
                                                checked={onDemandEnabled}
                                                disabled={isAnySwitchLoading(loadingToggles)}
                                                onChange={() => switchLevelEnableChange("onDemandLevel")}  
                                                loading={loadingToggles.onDemandLevel}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                            
                                        </Box>
                                        <Typography variant={"caption"}>
                                            Toggle On-Demand access. When enabled, select an access level from the dropdown.
                                        </Typography>
                                        <Box
                                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", mt: 4 }}
                                        >
                                            {onDemandEnabled && (
                                                <Autocomplete
                                                    options={[
                                                        "Tenancy",
                                                        "Department",
                                                        "Location",
                                                        "City",
                                                        "Country",
                                                        "Company Name",
                                                        "State",
                                                    ]}
                                                    getOptionLabel={(option) => option}
                                                    value={userDetails?.onDemandLevel}
                                                    fullWidth={true}
                                                    onChange={(event, newValue) => handleLevelChange(event, newValue, "onDemandLevel")}
                                                    renderInput={(params) => <TextField {...params} label="" />}
                                                />
                                            )}
                                        </Box>
                                    </MainCard>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarState.open}
                autoHideDuration={3000}
                onClose={() => setSnackbarState({ ...snackbarState, open: false })}
            >
                <Alert
                    onClose={() => setSnackbarState({ ...snackbarState, open: false })}
                    severity={snackbarState.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </>
    );
}
