import { useState } from "react";
import {useSelector} from "react-redux";
import {useGetOnDemandHistoryLogsQuery} from "../../../store/services/ondemand-service";
import Table from "../../../components/Table/Table";
import { formatDateTime } from "../../../utils/formatDateTime";

export default function HistoryLogsTable() {
    const {tenancyId} = useSelector((state) => state.tenancy);
    const { data: logs = [], isLoading: logsLoading, logsError } = useGetOnDemandHistoryLogsQuery({ tenancyId });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    let headCells = [
        { id: 'waitingTime', label: 'Scheduled', initialVisible: true },
        { id: 'sentTypeName', label: 'Content', initialVisible: true },
        { id: 'sentType', label: 'Format', initialVisible: true },
        { id: 'sentToName', label: 'Recipients', initialVisible: true },
        { id: 'status', label: 'Status', initialVisible: true },
    ];

    const formattedLogs = logs.map(log => ({
        ...log,
        waitingTime: formatDateTime(log.waitingTime)
    }));

    return (
        <Table
            name={'On-Demand History Logs'}
            data={formattedLogs}
            error={logsError}
            loading={logsLoading}
            headCells={headCells}
            search={false}
            editColumn={false}
            checkbox={false}
            toolbarActions={null}
            showToolbarActions={false}
            selected={[]}
            setSelected={[]}
            maxTableHeight={"calc(100vh - 250px)"}
            initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
        />
    )
}
