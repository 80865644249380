import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Table from "../../components/Table/Table";
import { useGetAuditLogsForTenancyQuery } from "../../store/services/tenancy.service";
import { Circle } from "@mui/icons-material";
import { formatDateTime } from "../../utils/formatDateTime";

AdminLogsTable.propTypes = {
    tab: PropTypes.string.isRequired
};

export default function AdminLogsTable(props) {
    const { tab } = props;
    const { tenancyId } = useSelector((state) => state.tenancy);
    const { data: auditLogsData, isLoading: auditLogsLoading, error: auditLogsError } = useGetAuditLogsForTenancyQuery({ tenancyId });
    const [selected, setSelected] = useState([]);
    const [filteredAuditLogs, setFilteredAuditLogs] = useState([]);
    const [headCells, setHeadCells] = useState([]);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    useEffect(() => {
        if (auditLogsData) {
            const filteredLogs = auditLogsData.filter((log) => log.type === tab);
            setFilteredAuditLogs(filteredLogs);
        }
    }, [auditLogsData, tab]);

    useEffect(() => {
        const newHeadCells = {
            UserCreation: [
                { id: "dateTime", label: "Date Time", render: (row) => formatDateTime(row.dateTime), initialVisible: true },
                { id: "groupName", label: "Group name", initialVisible: true },
                { id: "usersCreated", label: "Users created", render: (row) => `${row.usersCreated} out of ${row.usersToCreate}`, initialVisible: true },
                { id: "status", label: "Status", initialVisible: true }
            ],
            SendReminder: [
                { id: "dateTime", label: "Date Time", render: (row) => formatDateTime(row.dateTime), initialVisible: true },
                { id: "uniqueUsersCount", label: "Emails to send", render: (row) => parseInt(row.uniqueUsersCount), initialVisible: true },
                { id: "sentEmailCount", label: "Emails sent", render: (row) => parseInt(row.sentEmailCount), initialVisible: true },
                { id: "status", label: "Status", initialVisible: true }
            ],
            UserManagement: [
                { id: "dateTime", label: "Date Time", render: (row) => formatDateTime(row.dateTime), initialVisible: true },
                { id: "message", label: "Email address", dataKey: "message", initialVisible: true },
                { id: "status", label: "Status", initialVisible: true },
                { id: "requestorEmail", label: "Requested by", initialVisible: true },
            ]
        };

        setHeadCells(newHeadCells[tab]);
    }, [tab]);

    return (
        <Table
            name="Audit Logs"
            data={filteredAuditLogs}
            error={auditLogsError}
            loading={auditLogsLoading}
            headCells={headCells}
            search={true}
            editColumn={false}
            checkbox={false}
            toolbarActions={[]}
            showToolbarActions={false}
            onDelete={() => console.log("Delete Audit Log Not Allowed")}
            onCellClick={() => console.log("Cell Click Not Allowed")}
            selected={selected}
            setSelected={setSelected}
            maxTableHeight="calc(100vh - 250px)"
            initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
        />
    );
}
