import { Alert, Box, Button, Grid, Snackbar } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useUpdateTenancyMutation } from "../../store/services/tenancy.service";
import { useGetTinyJwtTokenQuery, useLazyGetTinyJwtTokenQuery } from "../../store/services/simulation-service";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from 'react-router-dom';

DefaultLandingPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default function DefaultLandingPage(props) {
    const { data } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    // Create local state for the landing page content
    const [tenancyData, setTenancyData] = useState(data);
    const [updateTenancy] = useUpdateTenancyMutation();

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [triggerJwtToken, { data: jwtToken, isLoading: jwtTokenLoading, error: jwtTokenError }] = useLazyGetTinyJwtTokenQuery();

    // Update local state when props change
    useEffect(() => {
        setTenancyData(data);
    }, [data]);

    function handleSave() {
        updateTenancy({ data: tenancyData, tenancyId: tenancyData.tenancyId })
            .then(() => {
                setSnackbarMessage("Default landing page settings updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarMessage("Error updating default landing page settings.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                console.log("Error updating default landing page settings:", error);
            });
    }

    const handleEditorChange = (newContent) => {
        setTenancyData(prev => ({
            ...prev,
            landingPage: newContent
        }));
    };

    const editorStyle = `
        body { 
            background-color: ${lightMode ? 'white' : theme.palette.black.main}; 
            color: ${lightMode ? 'black' : theme.palette.grey[50]}; 
        }
    `;

    const tinydriveTokenProvider = useCallback((callback) => {
        const getToken = async () => {
            try {
                const response = await triggerJwtToken().unwrap();
                if (response) {
                    callback(response);
                } else {
                    console.warn("JWT token not available in the response");
                    callback(null);
                }
            } catch (error) {
                console.error("Error fetching JWT token:", error);
                callback(null);
            }
        };

        getToken();
    }, [triggerJwtToken]);

    useEffect(() => {
        const style = document.createElement('style');
        style.textContent = `
        .tox-tinymce {
            border-color: ${lightMode ? theme.palette.grey[200] : theme.palette.black.light} !important;
        }
        .tox .tox-statusbar {
            background-color: ${lightMode ? 'inherit' : theme.palette.black.light} !important;
            color: ${lightMode ? 'inherit' : theme.palette.grey[300]} !important;
        }
    `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, [theme, lightMode]);

    if (tenancyData) {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Editor
                                apiKey="j2g2hy77ifpdh2oe7hfarnk6vvls82qlv49guynyzw6d22ha"
                                value={tenancyData.landingPage}
                                onEditorChange={handleEditorChange}
                                init={{
                                    skin: lightMode ? 'oxide' : 'oxide-dark',
                                    content_css: lightMode ? 'default' : 'dark',
                                    content_style: editorStyle,
                                    selector: ".tinymce",
                                    editable_class: 'mceEditable',
                                    plugins: [
                                        "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                                        "searchreplace", "visualblocks", "code", "fullscreen",
                                        "insertdatetime", "media", "table", "code", "help", "wordcount", "template", "tinydrive"
                                    ],
                                    images_file_types: "jpg,png,jpeg,webp",
                                    tinydrive_token_provider: tinydriveTokenProvider,
                                    toolbar:
                                        "template image | code | undo redo | fontselect fontsize fontsizeselect bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                                    templates: [
                                        {
                                            title: "User name",
                                            description: "Insert the users full name",
                                            content: "{user.name}"
                                        }
                                    ],
                                    height: "75vh",
                                    promotion: false,
                                    file_picker_types: 'media image'
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="black"
                            onClick={() => navigate(location.from ? location.from : -1)}
                            sx={{ width: "10%" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            color="warning"
                            sx={{ ml: 2, width: "10%" }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={snackbarSeverity}
                        variant="filled"
                        sx={{ width: "100%" }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </>
        );
    }
}